import $ from 'jquery'
import debounce from 'lodash/debounce'
import _get from 'lodash/get'
//
import { notifyMessage, notifyError, notifyProblem } from '@/services/notify'
import { ValidationError } from '@/services/errors'
import { workOrderItem } from '@/services/WorkOrders/UpdateWorkOrder/store'
import { workOrderList } from '@/services/WorkOrders/store'
import { hoaUtility } from '@/services/Hoa/store'
import { workOrderPhotoStore } from '@/services/WorkOrders/WorkOrderPhoto/store'
import { GetMultiplePhotos } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/procedures/GetMultiplePhotos'

export const methods = {
  locationTypeChanged() {
    if (this.locationType === 'Common Area') {
      console.debug('blur locationTypeChanged' + this.locationType)
      this.hideShowMapFlag = true

      if (this.hideShowMapFlag == true) {
        this.hideShowMapText = 'Hide Map'
        this.locationTypeText = 'Location Type - Place marker on map'
      }
    } else {
      this.hideShowMapFlag = false

      if (this.hideShowMapFlag == false) {
        this.hideShowMapText = 'Show Map'
        this.locationTypeText = 'Location Type'
      }
    }
  },
  hideShowMap() {
    console.debug('in hideShowMap...')
    this.hideShowMapFlag = !this.hideShowMapFlag

    if (this.hideShowMapFlag == true) {
      this.hideShowMapText = 'Hide Map'
    } else {
      this.hideShowMapText = 'Show Map'
    }
  },
  async getAccessToken() {
    await workOrderList
      .dispatch('getAccessToken', {
        credentialsName: 'google-maps-api-key'
      })
      .then(({ result }) => {
        if (result) {
          this.accessToken = result.credentialValue
        }
      })
  },
  async populateLocationTypes() {
    await workOrderList.dispatch('loadLocationTypesManagement').then(({ list }) => {
      if (list) {
        this.locationTypeList = list
        this.locationType = '<-Select a Location Type->'
      }
    })
  },
  async populateWorkOrderRequest() {
    if (this.workOrderRequest && this.workOrderRequest !== undefined) {
      if (this.isDebug == true)
        console.debug('populateWorkOrderRequest=' + JSON.stringify(this.workOrderRequest))

      this.formData.workOrderTypeID = this.workOrderRequest.workOrderTypeID
      this.formData.description = this.workOrderRequest.description
      this.formData.detailedDescription = this.workOrderRequest.detailedDescription
      this.locationType = this.workOrderRequest.locationType
      this.latitude = this.workOrderRequest.latitude
      this.longitude = this.workOrderRequest.longitude

      if (this.latitude === 0) {
        await this.loadHoaBoundsCoords()
        this.latitude = this.defaultCenterLat
        this.longitude = this.defaultCenterLng
      }
    }
  },
  async reloadParentImages(addedFile) {
    if (this.isDebug === true) console.debug('reloadParentImages...')

    this.files.push(addedFile)
  },
  async loadHoaBoundsCoords() {
    await hoaUtility
      .dispatch('getHoaById', {
        hoaID: this.hoaId
      })
      .then(({ result }) => {
        if (
          result &&
          result.northmostLatitude &&
          result.southmostLatitude &&
          result.eastmostLongitude &&
          result.westmostLongitude
        ) {
          this.defaultCenterLat = (result.northmostLatitude + result.southmostLatitude) / 2
          this.defaultCenterLng = (result.eastmostLongitude + result.westmostLongitude) / 2
        }
      })
  },
  async retrievePhotos({ photos }) {
    if (!Array.isArray(photos)) {
      return
    }

    const { problems, photos: photosContent, message } = await GetMultiplePhotos({
      paramsList: photos.map(photo => {
        const workOrderRequestPhotoID = _get(photo, ['workOrderRequestPhotoID'], '')

        return {
          workOrderRequestPhotoID,
          asBase64: true
        }
      })
    })
    if (problems !== 0) {
      notifyProblem(message)
      return
    }

    //base 64 to a file object, then inject this into the files array
    let counter = 1
    Array.from(photosContent).forEach(f => {
      console.log('testphotos=' + JSON.stringify(f.photo))
      if (f && f.photo) {
        const mimeType = (f.photo.match(/^data:([^;]+);/) || '')[1]
        const returnedFile = this.dataURLtoFile(
          f.photo,
          `request-file${counter}.${mimeType.replace('image/', '')}`
        )

        if (returnedFile) {
          this.files.push(returnedFile)
        }
      }

      counter++
    })
  },

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  },

  async loadAddressDropDownList() {
    await hoaUtility
      .dispatch('loadDropDownAddressList', {
        hoaID: this.hoaId
      })
      .then(({ list }) => {
        if (list) {
          this.addressDropDownList = list
        }
        if (this.addressDropDownList && this.addressDropDownList != undefined) {
          if (this.isDebug == true)
            console.debug('addressDropDownList=' + JSON.stringify(this.addressDropDownList))

          this.data = this.addressDropDownList

          this.tempAddress = []
          if (
            this.workOrderRequest &&
            this.workOrderRequest !== undefined &&
            this.workOrderRequest.sourceUnitID &&
            this.workOrderRequest.sourceUnitID !== undefined &&
            this.addressDropDownList
          ) {
            this.tempAddress = this.addressDropDownList.filter(
              i => i.unitID === this.workOrderRequest.sourceUnitID
            )

            if (this.tempAddress && this.tempAddress !== undefined && this.tempAddress[0]) {
              this.locationFilterQuery = this.tempAddress[0].label
            }
          }
        }
      })
  },
  async loadVendorDropDownList() {
    await hoaUtility
      .dispatch('loadVendors', {
        hoaID: this.hoaId
      })
      .then(({ list }) => {
        if (list) {
          this.vendorDropDownList = list
        }
        if (this.vendorDropDownList && this.vendorDropDownList != undefined) {
          if (this.isDebug == true)
            console.debug('vendorDropDownList=' + JSON.stringify(this.vendorDropDownList))

          this.vendorData = this.vendorDropDownList
        }
      })
  },
  async loadWorkOrderTypeList() {
    await workOrderItem
      .dispatch('getWorkOrderTypeList', {
        hoaId: this.hoaId
      })
      .then(({ list }) => {
        if (list) {
          this.workOrderTypeList = list
          this.populateWorkOrderRequest()
        }
      })
  },
  getPayload() {
    const address = this.addressDropDownList.filter(i => i.label.includes(this.locationFilterQuery))

    const vendor = this.vendorDropDownList.filter(i => i.label.includes(this.vendorFilterQuery))

    if (this.isDebug == true)
      console.debug(
        'this.formData=' +
          JSON.stringify(this.formData) +
          ', address=' +
          JSON.stringify(address) +
          ', vendor=' +
          JSON.stringify(vendor)
      )

    const payload = {
      sourceWorkOrderRequestID:
        this.workOrderRequest && this.workOrderRequest !== undefined
          ? this.workOrderRequest.workOrderRequestID
          : null,
      workOrderTypeID:
        this.formData.workOrderTypeID && this.formData.workOrderTypeID != undefined
          ? this.formData.workOrderTypeID
          : 0,
      vendorID: vendor && vendor[0] && vendor[0].value ? vendor[0].value : 0,
      hoaID: this.hoaId,
      unitID: address && address[0] && address[0].unitID ? address[0].unitID : 0,
      latitude: $('#formLatitude').text()
        ? $('#formLatitude').text()
        : this.latitude && this.latitude !== 0
        ? this.latitude
        : null,
      longitude: $('#formLongitude').text()
        ? $('#formLongitude').text()
        : this.longitude && this.longitude !== 0
        ? this.longitude
        : null,
      locationType: this.locationType,
      locationDescription:
        this.formData.locationDescription && this.formData.locationDescription != undefined
          ? this.formData.locationDescription
          : '',
      description:
        this.formData.description && this.formData.description != undefined
          ? this.formData.description
          : '',
      detailedDescription:
        this.formData.detailedDescription && this.formData.detailedDescription != undefined
          ? this.formData.detailedDescription
          : '',
      specialInstructions:
        this.formData.specialInstructions && this.formData.specialInstructions != undefined
          ? this.formData.specialInstructions
          : '',
      completeByDate:
        this.formData.completeByDate && this.formData.completeByDate != undefined
          ? this.formData.completeByDate
          : null
    }

    if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

    return payload
  },

  async onFormSubmit() {
    console.debug('in onFormSubmit...')
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem submitting your work order.')
      return
    }

    let workOrderID = ''

    try {
      const payload = this.getPayload()
      let validationMessages = ''

      if (payload.vendorID == 0) {
        validationMessages = '<div>Please select a valid Vendor.</div>'
      }

      if (payload.description == '') {
        validationMessages = '<div>Please enter a title for this work order.</div>'
      }

      if (payload.detailedDescription == '') {
        validationMessages = '<div>Please enter a description for this work order.</div>'
      }

      if (validationMessages != '') {
        notifyError(validationMessages)
        return
      }

      this.loading = true

      const { details } = await workOrderList.dispatch('addWorkOrder', {
        workOrderTypeID: payload.workOrderTypeID,
        sourceWorkOrderRequestID: payload.sourceWorkOrderRequestID,
        vendorID: payload.vendorID,
        hoaID: payload.hoaID,
        unitID: payload.unitID,
        locationDescription: payload.locationDescription,
        description: payload.description,
        detailedDescription: payload.detailedDescription,
        specialInstructions: payload.specialInstructions,
        latitude: payload.latitude,
        longitude: payload.longitude,
        locationType: payload.locationType,
        completeByDate: payload.completeByDate,
        done: async ({ details }) => {
          //save attachments
          if (details && details.workOrderID) {
            await this.saveAttachments(details.workOrderID)
          }

          // auto-close modal
          this.$emit('update:toggle', (this.toggle = false))
          $('#clickclose').click()
          this.loading = false
        }
      })

      workOrderID = _get(details, 'workOrderID', '')
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      notifyError('There was a problem saving this work order.' + e)

      this.loading = false
      return
    }

    await workOrderList.dispatch('transmitNotification', {
      workOrderID,
      done: ({ details }) => {
        console.debug('details=' + details)
        this.loading = false
      }
    })

    this.loading = false
  },

  async deleteDropFile(index) {
    this.files.splice(index, 1)

    const attachments = this.files

    const previews = this.previews
    this.previews.splice(index, 1)

    this.$store.dispatch('conversation/modify', {
      previews,
      attachments
    })
  },

  async updateAttachments() {
    console.log('updateAttachments...')
    const attachments = this.files
    if (!Array.isArray(attachments)) {
      notifyProblem('A problem occurred and the attachments could not be uploaded.')
      return
    }

    ////////////////////////////////
    //  Store previews
    ////////////////////////////////

    this.previews = this.files.map(file => {
      return URL.createObjectURL(file)
    })

    console.log('updateAttachments=' + JSON.stringify(this.previews))

    this.$store.dispatch('conversation/modify', {
      previews: this.previews,
      attachments
    })
  },

  async saveAttachments(id) {
    //Process one or more attachments
    try {
      if (this.isDebug === true) console.debug('files=' + this.file)

      if (this.files && this.files.length > 0) {
        for (let a = 0; a < this.files.length; a++) {
          await workOrderPhotoStore
            .dispatch('addWorkOrderPhoto', {
              workOrderID: id,
              description: this.files[a].name || ''
            })
            .then(async ({ returned }) => {
              if (returned && returned != undefined && returned.workOrderPhotoID > 0) {
                const payload = {
                  file: this.files[a],
                  existingWorkOrderPhotoID: returned.workOrderPhotoID,
                  workOrderID: id
                }

                await workOrderPhotoStore
                  .dispatch('uploadWorkOrderPhoto', {
                    payload: payload
                  })
                  .then(({ returnedfile }) => {
                    this.base64pdf = ''
                    if (returnedfile && returnedfile != undefined) {
                      notifyMessage('Successfully created the new work order with attachments.')
                      return
                    } else {
                      notifyError('There was a problem uploading your attachments.')
                      return
                    }
                  })
              }
            })
        }
      } else {
        notifyMessage('Successfully created the new conversation message.')
      }
    } catch (e) {
      notifyError(e)
    }
  },

  getVendorAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.vendorData = []
    }

    // String cleared
    if (!name.length) {
      this.vendorData = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.vendorDropDownList) {
      this.vendorData = this.vendorDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching = false
  }, 500),
  getVendorMoreAsyncData: debounce(function() {
    this.getVendorAsyncData(this.name)
  }, 250),

  getAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data = []
    }

    // String cleared
    if (!name.length) {
      this.data = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.addressDropDownList) {
      this.data = this.addressDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching = false
  }, 500),
  getMoreAsyncData: debounce(function() {
    this.getAsyncData(this.name)
  }, 250)
}
