/*
  example:
    import { GetMultiplePhotos } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/procedures/GetMultiplePhotos';

    const { photos, problems, message } = await GetMultiplePhotos ({
      paramsList: [{
        workOrderRequestPhotoID: 

        // optional
        asBase64: true,
        scaleToWidth: 
        scaleToHeight: 
      }]
    });
    if (problems !== 0) {
      notifyProblem (message);
      return;
    }
*/

/*
  example response:

*/
import _get from 'lodash/get'
//
import { getPhoto } from '../GetPhotoFile'
//
let message = ({ problems }) => {
  let verb = 'were'
  let amount = 's'
  if (problems === 1) {
    verb = 'was'
    amount = ''
  }

  return `${problems} photo${amount} ${verb} not retrieved successfully.`
}
//
export async function GetMultiplePhotos({ paramsList }) {
  const results = await Promise.all(
    paramsList.map(params => {
      return getPhoto({
        params
      })
    })
  )

  console.log({
    results
  })

  const photos = []
  let problems = 0
  for (let a = 0; a < results.length; a++) {
    const result = results[a]

    if (_get(result, 'successful', '') !== true) {
      problems++
      continue
    }

    photos.push({
      photo: _get(result, 'photo', ''),
      id: paramsList[a].workOrderRequestPhotoID
    })
  }

  return {
    photos,
    problems,
    message: message({
      problems
    })
  }
}
