var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"details-menu",attrs:{"role":"navigation"}},_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:tab.name},[_c('router-link',{staticClass:"h7",attrs:{"to":{
        name: ("" + _vm.prefix + (tab.name)),
        hash: '#tab',
        params: Object.assign(
          {
            index: index
          },
          _vm.params
        )
      }}},[_vm._v(" "+_vm._s(tab.label)+" ")])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }