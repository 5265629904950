<template>
  <div>
    <div class="is-size-4" v-if="formData">Create Work Order</div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <fieldset>
            <div class="columns">
              <div class="column is-6">
                <b-field :label="locationTypeText">
                  <b-select
                    @click.native="locationTypeChanged()"
                    v-model="locationType"
                    required="true"
                    placeholder="Select a Location Type"
                  >
                    <option
                      v-for="option in locationTypeList"
                      :value="option.value"
                      :key="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-6" v-if="locationType.indexOf('Property') > -1">
                <label class="label">Select Location / Unit</label>
                <b-autocomplete
                  size="is-small"
                  field="label"
                  icon="magnify"
                  :loading="isFetching"
                  placeholder="e.g. Location / Unit Search"
                  :data="data"
                  :open-on-focus="openOnFocus"
                  v-model="locationFilterQuery"
                  required="true"
                  @typing="getAsyncData"
                  @select="option => (selected = option)"
                >
                </b-autocomplete>
              </div>
            </div>
            <div class="columns">
              <div class="column is-6">
                <label class="label">Select Vendor</label>
                <b-autocomplete
                  size="is-small"
                  field="label"
                  icon="magnify"
                  :loading="isFetching"
                  placeholder="e.g. Vendor"
                  :data="vendorData"
                  :open-on-focus="openOnFocus"
                  v-model="vendorFilterQuery"
                  required="true"
                  @typing="getVendorAsyncData"
                  @select="option => (selected = option)"
                >
                </b-autocomplete>
              </div>
              <div class="column is-6" v-if="workOrderTypeList">
                <valid-select
                  label="Work Order Type"
                  placeholder="Work Order Types"
                  vid="workOrderTypeID"
                  required="true"
                  rules="required"
                  v-model="formData.workOrderTypeID"
                >
                  <option
                    v-for="option in workOrderTypeList"
                    :value="option.value"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </valid-select>
              </div>
            </div>
            <div class="columns">
              <div class="column is-6">
                <b-field label="Complete By Date">
                  <b-datepicker
                    v-model="formData.completeByDate"
                    label="Complete By Date"
                    :show-week-number="showWeekNumber"
                    placeholder="Click to select Complete By Date..."
                    icon="calendar-today"
                    trap-focus
                    editable
                  ></b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="is-12">
              <valid-input
                style="width:50px !important;"
                name="Summary"
                label="Title"
                type="text"
                vid="Summary"
                placeholder="Title"
                spellcheck="true"
                aria-label="Title"
                rules="max:100|required"
                class="is-small"
                :disabled="
                  formData.status &&
                  (formData.status === 'cancelled' || formData.status === 'promoted')
                    ? true
                    : false
                "
                v-model="formData.description"
              />
            </div>
            <div class="is-12 pt-5">
              <valid-input
                style="width:50px !important;"
                name="Description"
                label="Description"
                type="textarea"
                vid="Description"
                placeholder="Description"
                spellcheck="true"
                aria-label="Description"
                rules="required|max:8000"
                class="is-small"
                v-model="formData.detailedDescription"
              />
            </div>
            <div class="is-12 pt-5">
              <valid-input
                v-model="formData.locationDescription"
                :optional="true"
                class="is-small"
                style="width:50px !important;"
                name="LocationDescription"
                label="Location Description"
                type="textarea"
                vid="LocationDescription"
                placeholder="Location Description"
                spellcheck="true"
                aria-label="Location Description"
                rules="max:200"
              />
            </div>

            <div class="is-12 pt-5 pb-5">
              <valid-input
                v-model="formData.specialInstructions"
                :optional="true"
                style="width:50px !important;"
                name="SpecialInstructions"
                label="Special Instructions"
                type="textarea"
                vid="SpecialInstructions"
                placeholder="Special Instructions"
                spellcheck="true"
                aria-label="Special Instructions"
                rules="max:8000"
                class="is-small"
              />
            </div>
            <div
              v-if="hideMap !== true"
              @click="hideShowMap"
              style="cursor: pointer"
              class="href-overflow-wrap pt-5"
            >
              {{ hideShowMapText }}
              <i style="font-size: 8pt; vertical-align: baseline !important;" class="icon-map" />
            </div>
            <div v-if="hideMap !== true" class="font-bold">
              Please drag and drop the pin to represent the location of the work order.
            </div>
            <div
              class="pt-6 pb-6"
              v-if="hideShowMapFlag && (hideShowMapFlag === true || hideShowMapFlag === 'true')"
            >
              <GoogleMap
                mapStyle="satellite"
                :accessToken="accessToken"
                :longitude="Number.parseFloat(longitude ? longitude : 0)"
                :latitude="Number.parseFloat(latitude ? latitude : 0)"
                :zoomLevel="parseInt(19)"
                :workOrderId="parseInt(workOrderID)"
                :workOrderRequestId="parseInt(workOrderRequestID)"
                :reloadParent="reloadParentImages"
                :hideMap="hideMap"
              />
            </div>
            <div class="column is-12">
              <section class="pt-6 violation-image-upload">
                <b-field class="file">
                  <b-upload
                    v-model="files"
                    multiple
                    drag-drop
                    accept="image/png, image/jpeg, image/jpg"
                    class="border-dashed border-2 border-gray-400 flex flex-col justify-center items-center"
                  >
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large" />
                        </p>
                        <p>Drop your files here or click to upload</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>

                <ul id="gallery" class="flex flex-1 flex-wrap -m-1">
                  <li
                    v-show="!previews.length"
                    class="h-full w-full text-center flex flex-col items-center justify-center items-center"
                  >
                    <img
                      class="mx-auto w-32"
                      src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                      alt="no data"
                    />
                    <span class="text-small text-gray-500">No files selected</span>
                  </li>
                  <li v-for="(image, index) in previews" :key="index" class="p-1 flex w-1/4 h-32">
                    <div
                      class="align-items-center relative w-full h-full bg-cover"
                      :style="`background-image: url(${image});`"
                    >
                      <button
                        class="delete is-small"
                        type="button"
                        @click="deleteDropFile(index)"
                      />
                      <label class="absolute inset-x-0 bottom-0 bg-black bg-opacity-50 z-10 p-2">
                        <span style="color:white;">{{ files[index].name }}</span>
                      </label>
                    </div>
                  </li>
                </ul>
              </section>
            </div>

            <div :style="{ display: 'flex', flexWrap: 'wrap' }">
              <div
                v-for="(entry, index) in photos"
                :key="index"
                :style="{ height: '300px', width: '300px', margin: '4px' }"
              >
                <PictureFrame :src="entry.photo" :details="entry" :editMode="false" />
              </div>
            </div>

            <div class="column is-12" :style="{ display: 'flex' }">
              <b-checkbox v-model="transmit">transmit notification to vendor</b-checkbox>
              <b-tooltip
                label="Check to transmit a notification to the vendor after the work order is created."
                multilined
                :triggers="['click']"
                :style="{
                  cursor: 'pointer'
                }"
              >
                <InfoPlain />
              </b-tooltip>
            </div>

            <div class="column is-12">
              <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                Create
              </button>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState } from 'vuex' //mapGetters
import _get from 'lodash/get'
//
// components
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
import PictureFrame from '@/components/Picture/Frame'
import GoogleMap from '@/components/googlemaps'
import InfoPlain from '@/components/icons/InfoPlain'
//
import { methods } from './parts/methods'
import { data, DEFAULT_FORM_VALUES } from './parts/data'

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'WorkOrderModal',

  props: {
    workOrderRequest: Object,
    hidePromoteModal: String
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    ValidSelect,
    PictureFrame,
    GoogleMap,
    InfoPlain
  },

  data,

  async created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    locationFilterSuggestions() {
      if (
        this.locationFilterQuery &&
        this.addressDropDownList &&
        this.addressDropDownList != undefined
      ) {
        return this.addressDropDownList.filter(
          a => a.label.indexOf(this.addressDropDownList.toLowerCase()) !== -1
        )
      } else {
        return this.addressDropDownList
      }
    },
    vendorFilterSuggestions() {
      if (
        this.vendorFilterQuery &&
        this.vendorDropDownList &&
        this.vendorDropDownList != undefined
      ) {
        return this.vendorDropDownList.filter(
          a => a.label.indexOf(this.vendorDropDownList.toLowerCase()) !== -1
        )
      } else {
        return this.vendorDropDownList
      }
    }
  },

  watch: {
    files() {
      this.updateAttachments()
    }
  },

  async mounted() {
    this.loading = true

    await this.getAccessToken()

    if (this.hidePromoteModal && this.hidePromoteModal === 'true') {
      if (this.isDebug === true) console.debug('hidePromoteModal=' + this.hidePromoteModal)

      this.hideMap = true
    }

    this.formData = { ...DEFAULT_FORM_VALUES }

    await this.populateLocationTypes()

    this.loadAddressDropDownList()

    this.loadVendorDropDownList()

    this.loadWorkOrderTypeList()

    //Only retrieve if promoting from a request
    if (
      this.workOrderRequest &&
      this.workOrderRequest !== undefined &&
      this.workOrderRequest.workOrderRequestPhotos &&
      this.workOrderRequest.workOrderRequestPhotos !== undefined
    ) {
      this.workOrderID = null
      this.workOrderRequestID = this.workOrderRequest.workOrderRequestID

      console.debug('workOrderReqeuest promoting...' + JSON.stringify(this.workOrderRequest))

      await this.retrievePhotos({
        photos: _get(this.workOrderRequest, ['workOrderRequestPhotos'], [])
      })
    } else {
      this.workOrderID = -1
    }

    if (this.latitude === 0) {
      await this.loadHoaBoundsCoords()
      this.latitude = this.defaultCenterLat
      this.longitude = this.defaultCenterLng
    }

    this.loading = false
  },

  methods
}
</script>

<style lang="scss">
#work-order-modal .step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

#work-order-modal .b-steps.is-small .steps {
  margin-top: 1rem;
}

#work-order-modal .b-steps .steps + .step-content {
  padding-top: 0 !important;
}

#work-order-modal .step-navigation {
  display: flex;
  justify-content: flex-end;
}

#work-order-modal .my-grid-class {
  height: 400px;
}

#work-order-modal tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
