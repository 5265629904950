var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
    height: '0',
    paddingBottom: '100%',
    marginBottom: '6px',

    overflow: 'hidden',
    borderRadius: '6px',

    cursor: 'pointer',
    boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
  }),on:{"click":_vm.clicked}},[_c('transition',{attrs:{"name":"fade"}},[_c('img',{style:({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',

        flexShrink: '0',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
      }),attrs:{"src":_vm.src}})]),(typeof _vm.label === 'string' && _vm.label.length >= 1)?_c('label',{staticClass:"absolute inset-x-0 bottom-0 bg-black bg-opacity-50 z-10 p-2"},[_c('span',{style:({ color: 'white' })},[_vm._v(_vm._s(_vm.label))])]):_vm._e(),(typeof _vm.erase === 'function' && _vm.editMode === true)?_c('button',{staticClass:"delete",style:({ position: 'absolute', top: '10px', left: '10px' }),attrs:{"type":"button"},on:{"click":function($event){return _vm.erase({ index: _vm.index, details: _vm.details })}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }