import _get from 'lodash/get'
import Button from '@/components/buttons/Button'
import { parseDate } from '@/utilities/Date/parse'
import { workOrderPhotoStore } from '@/services/WorkOrders/WorkOrderPhoto/store'

export const methods = {
  confirmDeletePhoto(id, description) {
    this.$buefy.dialog.confirm({
      title: 'Delete Work Order Photo',
      message: `Are you sure you want to <b>delete</b> the work order photo for: "${description}"?`,
      confirmText: 'Delete Work Order Photo',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deletePhoto(id)
    })
  },

  async deletePhoto(id) {
    await workOrderPhotoStore.dispatch('deleteWorkOrderPhoto', {
      workOrderPhotoID: id,
      done: () => {
        this.loading = false
        this.selectedPhoto = null
        this.reload()
      }
    })
  },

  processPhotoUpdate(photo) {
    this.photoToggle = true
    this.selectedPhoto = photo
    this.photoViewOnly = false
  },

  createPhotoModal() {
    this.selectedPhoto = null
    this.photoToggle = true
    this.photoViewOnly = false
  },

  processPhotoView(photo) {
    this.photoToggle = true
    this.selectedPhoto = photo
    this.photoViewOnly = true
  },

  determineRows(list) {
    const dateFormat = 'LL'

    if (list) {
      this.photoList = list.map(entry => {
        const description = _get(entry, 'description', '')
        const tempDate = _get(entry, 'createdDate', '')
        const createdDate = parseDate(tempDate, dateFormat)
        const workOrderPhotoId = _get(entry, 'workOrderPhotoID', 0)

        let isAuthenticated = _get(entry, 'isAuthenticated', false)

        return {
          description: description,
          createdDate: createdDate,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processPhotoUpdate(entry)
              },
              text: 'Edit',
              visible: isAuthenticated == true ? true : false
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeletePhoto(workOrderPhotoId, description)
              },
              text: 'Delete',
              visible: isAuthenticated == true ? true : false
            }
          },
          view: {
            component: Button,
            props: {
              onClick: () => {
                this.processPhotoView(entry)
              },
              text: 'View',
              visible: isAuthenticated == false || isAuthenticated == null ? true : false
            }
          }
        }
      })
    }
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async loadPhotoList() {
    workOrderPhotoStore
      .dispatch('getWorkOrderPhotoList', {
        workOrderID: this.workOrderID
      })
      .then(({ list }) => {
        if (list) {
          this.determineRows(list)
        }
      })
  },

  async reload() {
    this.loading = true

    this.photoViewOnly = false

    this.workOrderID = this.$route.params.id || 0

    await this.loadPhotoList()

    this.rows = []

    this.loading = false
  }
}
