<template>
  <ul class="details-menu" role="navigation">
    <li v-for="(tab, index) in tabs" :key="tab.name">
      <router-link
        class="h7"
        :to="{
          name: `${prefix}${tab.name}`,
          hash: '#tab',
          params: Object.assign(
            {
              index
            },
            params
          )
        }"
      >
        {{ tab.label }}
      </router-link>
    </li>
  </ul>
</template>

<script>
/*
  Example:

    <DetailsMenu
      :tabs="[{
        name: '',
        label: ''
      }]"
      prefix='accountSettings.'
      params="{

      }"
    />
*/

export default {
  props: {
    tabs: Array,
    prefix: {
      type: String,
      default: ''
    },
    to: Object,
    params: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: function() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.details-menu {
  @media screen and (min-width: $tablet) {
    a {
      font-size: 16px !important;
    }
  }

  a {
    color: #838383;
    display: inline-block;
    padding: 0.5em 0;
    font-size: 18px;
    width: 100%;

    &:hover,
    &:active {
      color: inherit;
    }

    &.router-link-active {
      color: #ff7300; // #381;

      &::after {
        visibility: visible;
      }
    }

    &::after {
      visibility: hidden;
      content: '→';
    }
  }
}
</style>
