/*
  example:
    import { StoreMultiplePhotos } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/procedures/StoreMultiplePhotos';

    const { problems, message } = await StoreMultiplePhotos ({
      photo,
      params: {
        workOrderRequestID
      }
    });
    if (problems >= 1) {
      notifyProblem (message);
    }
*/

/*
  example response:

*/
import _get from 'lodash/get'
//
import { StorePhoto } from '../StorePhotoFile'
//
let message = ({ problems }) => {
  let verb = 'were'
  let amount = 's'
  if (problems === 1) {
    verb = 'was'
    amount = ''
  }

  return `A problem occurred and ${problems} photo${amount} ${verb} not stored successfully.`
}
//
export async function StoreMultiplePhotos({ photos, params }) {
  if (!Array.isArray(photos) || photos.length === 0) {
    return {
      problems: 0,
      message: ''
    }
  }

  const results = await Promise.all(
    photos.map(photo => {
      return StorePhoto({
        params,
        photo
      })
    })
  )

  let problems = 0
  for (let a = 0; a < results.length; a++) {
    const result = results[a]

    if (_get(result, 'successful', '') !== true) {
      problems++
      continue
    }
  }

  return {
    problems,
    message: message({
      problems
    })
  }
}
