import debounce from 'lodash/debounce'
import { ValidationError } from '@/services/errors'
import { ToastProgrammatic as Toast } from 'buefy'
import { workOrderItem } from '@/services/WorkOrders/UpdateWorkOrder/store'
import { hoaUtility } from '@/services/Hoa/store'
import { workOrderList } from '@/services/WorkOrders/store'
import _get from 'lodash/get'
import moment from 'moment'
const today = moment()

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

var DEFAULT_FORM_VALUES = {
  workOrderID: 0,
  completeByDate: null,
  sentToVendorDate: null,
  completedDate: null,
  workOrderTypeID: 0,
  workOrderType: '',
  fullAddress: '',
  vendor: '',
  locationDescription: '',
  shortDescription: '',
  detailedDescription: '',
  specialInstructions: '',
  completedNote: '',
  latitude: 0,
  longitude: 0
}

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async processWorkOrderUpdate(workOrder) {
    if (!workOrder || workOrder == undefined) {
      notifyError('Could not update the work order at this time.')
      return
    }

    await workOrderItem.dispatch('updateWorkOrder', {
      payload: workOrder,
      done: () => {
        this.loading = false
        this.reload()
      }
    })
  },

  async reload() {
    this.loading = true

    this.workOrderID = this.$route.params.id

    if (this.isDebug == true) console.debug('workOrderID=' + this.workOrderID)

    this.formData = { ...DEFAULT_FORM_VALUES }

    await this.getAccessToken()

    this.formData.latitude = 0
    this.formData.longitude = 0

    try {
      await workOrderItem
        .dispatch('getWorkOrderById', {
          workOrderID: this.workOrderID
        })
        .then(async ({ workorder }) => {
          if (this.isDebug == true) console.debug('workOrder=' + JSON.stringify(workorder))

          if (workorder) {
            this.workOrder = workorder
            this.vendorHoaId = this.workOrder.hoaID
          }

          if (this.workOrderID && this.workOrder && this.workOrder != undefined) {
            this.formData.workOrderTypeID = this.workOrder.workOrderTypeID
              ? this.workOrder.workOrderTypeID
              : 0

            this.formData.workOrderType = _get(this.workOrder, 'workOrderType.name', '')

            this.formData.vendor = _get(this.workOrder, 'vendor.doingBusinessAs', '')

            this.vendor = _get(this.workOrder, 'vendor', null)

            this.formData.locationDescription = this.workOrder.locationDescription
              ? this.workOrder.locationDescription
              : ''
            this.formData.shortDescription = this.workOrder.description
            this.formData.description = this.workOrder.detailedDescription
            this.formData.title = this.workOrder.description
            this.formData.locationDescription = this.workOrder.locationDescription
              ? this.workOrder.locationDescription
              : ''
            this.formData.specialInstructions = this.workOrder.specialInstructions
              ? this.workOrder.specialInstructions
              : ''
            this.formData.completedNote = this.workOrder.completionNote
              ? this.workOrder.completionNote
              : ''

            this.formData.completeByDate =
              this.workOrder.completeByDate != null ? new Date(this.workOrder.completeByDate) : null
            this.formData.sentToVendorDate =
              this.workOrder.sentToVendorDate != null
                ? new Date(this.workOrder.sentToVendorDate)
                : null
            this.formData.completedDate =
              this.workOrder.completionDate != null ? new Date(this.workOrder.completionDate) : null

            const address1 = this.workOrder.unit.address.addressOne
            const address2 = this.workOrder.unit.address.addressTwo
            const addressCity = this.workOrder.unit.address.city
            const addressState = this.workOrder.unit.address.state
            const addressPostal = this.workOrder.unit.address.postalCode

            const fullAddress = `${address1 ? address1 + ', ' : ''}${address2 ? address2 : ''}${
              addressCity ? addressCity : ''
            } ${addressState ? addressState : ''}  ${addressPostal ? addressPostal : ''}`

            this.formData.fullAddress = fullAddress ? fullAddress : ''

            this.formData.latitude =
              this.workOrder.latitude && this.workOrder.latitude !== 0 ? this.workOrder.latitude : 0
            this.formData.longitude =
              this.workOrder.longitude && this.workOrder.longitude !== 0
                ? this.workOrder.longitude
                : 0

            if (this.formData.latitude === 0) {
              await this.loadHoaBoundsCoords()
              this.formData.latitude = this.defaultCenterLat ? this.defaultCenterLat : 0
              this.formData.longitude = this.defaultCenterLng ? this.defaultCenterLng : 0
            }
          }
        })
    } catch (e) {
      console.debug('error while loading reload work order update method:' + e)
    }

    this.loading = false
  },

  async reloadParentImages() {
    if (this.isDebug === true) console.debug('reloadParentImages...')
  },

  async getAccessToken() {
    await workOrderList
      .dispatch('getAccessToken', {
        credentialsName: 'google-maps-api-key'
      })
      .then(({ result }) => {
        if (result) {
          this.accessToken = result.credentialValue
        }
      })
  },

  hideShowMap() {
    console.debug('in hideShowMap...')
    this.hideShowMapFlag = !this.hideShowMapFlag

    if (this.hideShowMapFlag == true) {
      this.hideShowMapText = 'Hide Map'
    } else {
      this.hideShowMapText = 'Show Map'
    }
  },

  async loadHoaBoundsCoords() {
    await hoaUtility
      .dispatch('getHoaById', {
        hoaID: this.vendorHoaId
      })
      .then(({ result }) => {
        if (
          result &&
          result.northmostLatitude &&
          result.southmostLatitude &&
          result.eastmostLongitude &&
          result.westmostLongitude
        ) {
          this.defaultCenterLat = (result.northmostLatitude + result.southmostLatitude) / 2
          this.defaultCenterLng = (result.eastmostLongitude + result.westmostLongitude) / 2
        }
      })
  },

  returnToParent() {
    this.$router.push({
      name: 'workOrders'
    })
  },

  completeWorkOrder() {
    this.currentWorkOrder = this.workOrder
    this.completeToggle = true
  },

  getPayload() {
    const address = this.addressDropDownList.filter(i => i.label.includes(this.addressFilterQuery))

    const vendor = this.vendorDropDownList.filter(i => i.label.includes(this.vendorFilterQuery))

    if (this.isDebug === true) console.debug('vendor=' + JSON.stringify(vendor))

    const payload = {
      workOrderID: this.workOrderID,
      workOrderTypeID:
        this.formData.workOrderTypeID && this.formData.workOrderTypeID != undefined
          ? this.formData.workOrderTypeID
          : 0,
      vendorID: vendor && vendor[0] && vendor[0].value ? vendor[0].value : 0,
      hoaID: this.workOrder.hoaID,
      unitID: address && address[0] && address[0].unitID ? address[0].unitID : 0,
      locationDescription:
        this.formData.locationDescription && this.formData.locationDescription != undefined
          ? this.formData.locationDescription
          : '',
      description:
        this.formData.shortDescription && this.formData.shortDescription != undefined
          ? this.formData.shortDescription
          : '',
      detailedDescription:
        this.formData.detailedDescription && this.formData.detailedDescription != undefined
          ? this.formData.detailedDescription
          : '',
      specialInstructions:
        this.formData.specialInstructions && this.formData.specialInstructions != undefined
          ? this.formData.specialInstructions
          : '',
      completionNote:
        this.formData.completedNote && this.formData.completedNote != undefined
          ? this.formData.completedNote
          : '',
      completeByDate:
        this.formData.completeByDate && this.formData.completeByDate != undefined
          ? this.formData.completeByDate
          : null,
      sentToVendorDate:
        this.formData.sentToVendorDate && this.formData.sentToVendorDate != undefined
          ? this.formData.sentToVendorDate
          : null,
      completionDate:
        this.formData.completedDate && this.formData.completedDate != undefined
          ? this.formData.completedDate
          : null
    }

    if (this.isDebug == true)
      console.debug(
        'payload=' +
          JSON.stringify(payload) +
          ', address=' +
          JSON.stringify(this.addressFilterQuery)
      )

    return payload
  },
  async onFormSubmit() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem updating your work order.')
      return
    }

    if (this.formData.sentToVendorDate && this.formData.sentToVendorDate != undefined) {
      if (this.formData.sentToVendorDate > today.toDate()) {
        notifyError('The "Sent to Vendor" date cannot be set to a future date.')
        return
      }
    }

    try {
      const payload = this.getPayload()
      let validationMessages = ''

      if (!payload.workOrderTypeID || payload.workOrderTypeID == 0) {
        validationMessages = '<div>Please select a valid work order type.</div>'
      }

      if (payload.vendorID == null) {
        validationMessages += '<div>Please select a valid vendor.</div>'
      }

      if (payload.description == null) {
        validationMessages += '<div>Please enter a description.</div>'
      }

      if (payload.detailedDescription == null) {
        validationMessages += '<div>Please enter a detailed description.</div>'
      }

      if (validationMessages != '') {
        notifyError(validationMessages)
        return
      }

      await workOrderItem.dispatch('updateWorkOrder', {
        payload: payload,
        done: () => {
          this.reload()
          this.loading = false
        }
      })
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      notifyError('There was a problem saving the work order.' + e)
    }
    this.loading = false
  },

  getVendorAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.vendorData = []
    }

    // String cleared
    if (!name.length) {
      this.vendorData = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.vendorDropDownList) {
      this.vendorData = this.vendorDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching = false
  }, 500),
  getVendorMoreAsyncData: debounce(function() {
    this.getVendorAsyncData(this.name)
  }, 250),

  getAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.addressData = []
    }

    // String cleared
    if (!name.length) {
      this.addressData = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.addressDropDownList) {
      this.addressData = this.addressDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching = false
  }, 500),
  getMoreAsyncData: debounce(function() {
    this.getAsyncData(this.name)
  }, 250)
}
