/*
  import { workOrderNoteStore } from '@/services/WorkOrders/WorkOrderNote/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  //Work Order by Id
  async getWorkOrderById({}, { workOrderID }) {
    try {
      console.debug('in getWorkOrderById...')
      const workorder = await kms.get(`/WorkOrders/WorkOrder/${workOrderID}`)

      if (isDebug == true) console.debug('getWorkOrderById=' + JSON.stringify(workorder))

      return {
        workorder: workorder
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the work order.`)
      console.error(exception)
    }

    return {
      workorder: {}
    }
  },

  async getWorkOrderNoteList({}, { workOrderID }) {
    console.log('in getWorkOrderNoteList...')
    try {
      const result = await kms.get('/WorkOrders/WorkOrderNote/List', {
        params: {
          workOrderID
        }
      })

      if (isDebug == true) console.debug('getWorkOrderNoteList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The work order notes list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  // Add Work Order Note
  async addWorkOrderNote({ dispatch }, { workOrderID, note, aspNetUserID, done }) {
    try {
      const results = await kms.post(`/WorkOrders/WorkOrderNote`, {
        workOrderID,
        aspNetUserID,
        note
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderNoteID > 0) {
        notifyMessage(`Successfully added the work order note.`)
        done()
      } else {
        notifyError('There was a problem adding this work order note.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this work order note.`)
      console.error(exception)
    }
  },

  // Update Work Order Note
  async updateWorkOrderNote({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/WorkOrders/WorkOrderNote`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderID > 0) {
        notifyMessage(`Successfully updated the work order note.`)
        done()
      } else {
        notifyError('There was a problem updating this work order note.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem updating this work order note.`)
      console.error(exception)
    }
  },

  async deleteWorkOrderNote({ dispatch }, { workOrderNoteID, done }) {
    try {
      const path = `/WorkOrders/WorkOrderNote/${workOrderNoteID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the work order note.`)
        done()
      } else {
        notifyError('There was a problem deleting the work order note.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting the work order note.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const workOrderNoteStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
