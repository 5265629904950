import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import { workOrderVendorList } from '@/services/Vendors/WorkOrders/store'
import Button from '@/components/buttons/Button'

export const methods = {
  determineRows: function(list) {
    const dateFormat = 'LL'

    var rows = list
      .map(entry => {
        const dateCreated = parseDate(_get(entry, 'createdDate', null), dateFormat)

        const tempDateCompleted = _get(entry, 'completionDate', null)

        const dateCompleted = tempDateCompleted ? parseDate(tempDateCompleted, dateFormat) : null
        const hoaName = _get(entry, 'vendorName', '')
        const detailedDescription = _get(entry, 'detailedDescription', null)
        const type = _get(entry, 'workOrderTypeName')
        const locationDescription = _get(entry, 'locationDescription', '')
        const title = _get(entry, 'description', '')

        return {
          hoa: hoaName,
          type: type,
          title: title,
          description: detailedDescription,
          address: locationDescription,
          dateCreated: {
            date: dateCreated,
            format: dateFormat
          },
          dateCompleted: {
            date: dateCompleted,
            format: dateFormat
          },
          details: {
            component: Button,
            props: {
              onClick: () => {
                this.processWorkOrderDetail(entry)
              },
              text: 'Details'
            }
          },
          button: {
            component: Button,
            props: {
              onClick: () => {
                this.redirectToNewConversation(entry)
              },
              text: 'Ask a Question'
            }
          }
        }
      })
      .filter(row => {
        if (this.statusFilter === 'complete') {
          return _get(row, 'dateCompleted.date', null) !== null
        } else if (this.statusFilter === 'incomplete') {
          return _get(row, 'dateCompleted.date', null) === null
        } else {
          return true
        }
      })

    this.rows = rows
  },

  redirectToNewConversation(workorder) {
    if (workorder) {
      let vendorName = workorder.vendorName ? workorder.vendorName : ''
      if (vendorName === '') {
        vendorName = 'None'
      }

      this.$router.push({
        name: 'newconversation',
        params: {
          workorderid: workorder.workOrderID,
          workordervendorid: workorder.vendorID,
          workorderlabel: `Add Vendor - ${vendorName}`,
          workorderhoaid: workorder.hoaID
        }
      })
    }
  },

  async reload() {
    console.debug('in reload...' + JSON.stringify(this.authUser))
    this.loading = true

    this.statusFilter = _get(this.$route.params, 'filter', 'all')

    await this.loadWorkOrderVendorList()
  },

  async reloadFilter() {
    console.debug('in reloadFilter...' + JSON.stringify(this.authUser))
    this.loading = true

    await this.loadWorkOrderVendorList()
  },

  async loadWorkOrderVendorList() {
    await workOrderVendorList
      .dispatch('getWorkOrderVendorList', {
        vendorGlobalID: _get(this, 'authUser.vendorGlobalID', '')
      })
      .then(({ list }) => {
        this.determineRows(list)
        this.loading = false
      })
  },

  processWorkOrderDetail(workOrder) {
    if (workOrder) {
      this.selectedWorkOrder = workOrder

      const path = `/workordertabs/${workOrder.workOrderID}/workorderDetails#tab`

      this.$router.push(path)
    }
  }
}
