<template>
  <div
    :style="{
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      width: '100%',
      height: '0',
      paddingBottom: '100%',
      marginBottom: '6px',

      overflow: 'hidden',
      borderRadius: '6px',

      cursor: 'pointer',
      boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
    }"
    @click="clicked"
  >
    <transition name="fade">
      <img
        :src="src"
        :style="{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',

          flexShrink: '0',
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain'
        }"
      />
    </transition>

    <label
      class="absolute inset-x-0 bottom-0 bg-black bg-opacity-50 z-10 p-2"
      v-if="typeof label === 'string' && label.length >= 1"
    >
      <span :style="{ color: 'white' }">{{ label }}</span>
    </label>

    <button
      v-if="typeof erase === 'function' && editMode === true"
      :style="{ position: 'absolute', top: '10px', left: '10px' }"
      class="delete"
      type="button"
      @click="erase({ index, details })"
    />
  </div>
</template>

<script>
/*
  import PictureFrame from '@/components/Picture/Frame'


  <PictureFrame src="" :clicked="" />

  <PictureFrame src="" :erase="" />
 */
export default {
  props: {
    clicked: {
      type: Function,
      default() {}
    },
    label: {
      type: String,
      default: ''
    },
    src: String,
    erase: [Function, null],

    //
    index: Number,
    details: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editMode: {
      type: Boolean,
      default: false
    }
  }
}
</script>
