<template>
  <div class="section uploader">
    <div class="container">
      <div class="is-size-4">{{ `${title} Work Order Document` }}</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="Description"
                    label="Description"
                    type="textarea"
                    vid="Description"
                    placeholder="Description"
                    spellcheck="true"
                    aria-label="Description"
                    rules="max:8000|required"
                    class="is-small"
                    v-model="description"
                  />
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
        <div class="box is-8">
          <section>
            <div v-if="base64pdf" class="has-text-centered">
              <div>
                <div>
                  <div v-dragscroll="true" :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]">
                    <pdf
                      style="display: none;"
                      :src="base64pdf"
                      @num-pages="pageCount = new Array($event)"
                    />
                    <!-- Actual document -->
                    <pdf
                      v-for="(page, index) in pageCount"
                      :key="index"
                      :page="index + 1"
                      :src="`${base64pdf}`"
                      :scale="scale"
                      :resize="true"
                      :style="{ margin: 'auto', width: `${100 * scale}%` }"
                    />
                  </div>
                </div>
                <div class="doc-controls has-text-center pb-6">
                  <b-field position="is-centered">
                    <div class="control">
                      <b-button @click="zoom(-1)">-</b-button>
                    </div>
                    <div class="control">
                      <b-button @click="zoom(1)">+</b-button>
                    </div>
                    <div class="pl-6 control">
                      <b-button @click="downloadPdf"
                        ><svg
                          class="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                          ></path></svg
                      ></b-button>
                    </div>
                  </b-field>
                </div>
              </div>
            </div>
            <div v-else-if="imageContent">
              <img :src="imageContent" />
            </div>
            <div v-if="!documentViewOnly">
              <!-- Show the Upload Form -->
              <b-field class="file">
                <b-upload v-model="file" drag-drop accept="application/pdf" class="rectangle">
                  <section class="section">
                    <div class="">
                      <div>
                        <img
                          class="Fill-1-Copy-11"
                          src="@/assets/images/fill-1-copy-11.svg"
                          alt="Upload"
                        />
                      </div>
                      <div v-if="file">
                        {{ file.name }}
                      </div>
                      <div v-else>
                        <div class="Drag-Drop-your-fil">
                          <div class="text-style-1">Drag & Drop</div>
                          <div>your files here to upload</div>
                        </div>
                      </div>
                    </div>
                  </section>
                </b-upload>
              </b-field>
            </div>
            <div class="pt-5">
              <span
                class="pr-5"
                v-if="attachment == false && !workOrderDocumentProp && !documentViewOnly"
                ><b-button type="is-primary" :disabled="!file" @click="submitFile"
                  >Upload</b-button
                ></span
              >
              <span
                class="pr-5"
                v-if="
                  workOrderDocumentProp && workOrderDocumentProp != undefined && !documentViewOnly
                "
                ><b-button type="is-primary" :disabled="!file" @click="replaceFile"
                  >Replace</b-button
                ></span
              >
              <span v-if="workOrderDocumentProp && !documentViewOnly" class="pt-5 pr-5">
                <b-button type="is-primary" v-if="attachment == true" @click="updateModal"
                  >Update</b-button
                >
              </span>
              <span
                v-show="false"
                v-if="
                  workOrderDocumentProp && attachment == true && this.base64pdf && !documentViewOnly
                "
                class="pt-5 pr-5"
              >
                <b-button type="is-primary" @click="removeFile">Remove</b-button>
              </span>
              <span><b-button type="is-primary" @click="closeModal">Close</b-button></span>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState } from 'vuex'
import { dragscroll } from 'vue-dragscroll'
import pdf from 'vue-pdf'
import moment from 'moment'
import $ from 'jquery'
import { notifyProblem, notifyMessage, notifyWarning, notifyError } from '@/services/notify'
import { base64toBlob, downloadPdf } from '@/services/blobutility'
import { workOrderDocumentStore } from '@/services/WorkOrders/WorkOrderDocument/store'

// components
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'WorkOrderDocumentModal',
  components: {
    pdf,
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  directives: { dragscroll },

  props: {
    workOrderID: Number,
    workOrderDocumentProp: Object,
    documentViewOnly: Boolean
  },

  data: function() {
    return {
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      file: null,
      base64pdf: '',
      pageCount: [],
      scale: 1,
      searchTerm: -1,
      imageContent: null,
      description: '',
      title: 'Create',
      attachment: false,
      workOrderDocumentFile: null,
      workOrderDocument: null,
      uploadedWorkOrderDocumentFile: null
    }
  },

  mounted() {
    this.description = ''
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    reloadDocuments() {
      this.reload()
    }
  },

  methods: {
    reload() {
      if (this.isDebug == true)
        console.debug('workOrderDocumentProp=' + JSON.stringify(this.workOrderDocumentProp))

      if (this.isDebug == true)
        console.debug('reload workOrderDocumentProp=' + this.workOrderDocumentProp)

      if (
        this.workOrderDocumentProp &&
        this.workOrderDocumentProp.workOrderDocumentID &&
        this.workOrderDocumentProp.workOrderDocumentID > 0
      ) {
        this.title = 'Update'

        this.description = this.workOrderDocumentProp.description
        if (
          this.workOrderDocumentProp.documentID &&
          this.workOrderDocumentProp.documentID != undefined
        ) {
          this.attachment = true
          this.loadCurrentDocument()
        }
      } else {
        this.title = 'Create'
        this.description = ''
        this.attachment = false
      }
    },

    async loadCurrentDocument() {
      if (
        !this.workOrderDocumentProp ||
        !this.workOrderDocumentProp.documentID ||
        !this.workOrderDocumentProp.documentFileName ||
        this.workOrderDocumentProp.documentID <= 0
      ) {
        return
      }

      this.searchTerm = this.workOrderDocumentProp.documentFileName.indexOf('.pdf')

      try {
        const params = {
          workOrderDocumentID: this.workOrderDocumentProp.workOrderDocumentID,
          asBase64: true
        }

        await workOrderDocumentStore
          .dispatch('getWorkOrderDocumentGetFile', {
            params: params
          })
          .then(({ returned }) => {
            if (returned) {
              this.workOrderDocumentFile = returned
            }

            if (this.isDebug == true)
              console.debug(
                ' - params' + JSON.stringify(params) + ' - this.workOrderDocumentFile=' + returned
              )
            if (this.workOrderDocumentFile && this.workOrderDocumentFile != undefined) {
              if (this.isDebug == true)
                console.debug(
                  'image or pdf............this.searchTerm:' +
                    this.searchTerm +
                    '............' +
                    this.workOrderDocumentFile
                )

              if (this.searchTerm != -1) {
                this.base64pdf = this.workOrderDocumentFile
                this.imageContent = null
              } else {
                this.imageContent = this.workOrderDocumentFile
                this.base64pdf = null
              }
            }
          })
      } catch (e) {
        if (this.isDebug == true) console.debug(e)
      }
    },

    async downloadPdf() {
      if (this.isDebug == true) console.debug('downloadPdf...')

      if (!this.base64pdf || this.base64pdf === undefined) {
        notifyProblem('There was a problem opening the document.')
      }

      try {
        const returned = this.base64pdf.replace('data:application/pdf;base64,', '')
        if (returned && returned != undefined) {
          if (this.isDebug == true) console.debug('base64pdf........................' + returned)
          const contentType = 'application/pdf'
          const blob = base64toBlob(returned, contentType)
          downloadPdf(blob)
          notifyMessage(`Successfully downloaded this document.`)
          return
        }
      } catch (e) {
        if (this.isDebug == true) console.debug(e)
        notifyWarning('There was a problem downloading this document.')
      }
    },

    closeModal() {
      this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

      // auto-close modal
      $('#clickclose').click()
    },
    async removeFile() {
      if (this.workOrderDocumentProp && this.workOrderDocumentProp != undefined) {
        try {
          if (
            this.workOrderID <= 0 ||
            !this.workOrderDocumentProp ||
            this.workOrderDocumentProp == undefined
          ) {
            notifyError('There was a problem removing this document.')
            return
          }

          if (this.description == '') {
            notifyError('Description is a required field.')
            return
          }

          const payload = {
            workOrderDocumentID: this.workOrderDocumentProp.workOrderDocumentID,
            workOrderID: this.workOrderID,
            description: this.description,
            documentId: null
          }

          //Update
          await workOrderDocumentStore.dispatch('updateWorkOrderDocument', {
            payload: payload,
            done: () => {
              this.loading = false
              this.workOrderDocumentProp.documentID = null
              this.base64pdf = null
              this.attachment = false
              this.reload()
            }
          })
        } catch (e) {
          notifyError(e)
        }
        this.loading = false
      } else {
        notifyError('There was a problem removing this document.')
      }
    },

    async updateModal() {
      if (
        this.workOrderID <= 0 ||
        !this.workOrderDocumentProp ||
        this.workOrderDocumentProp == undefined
      ) {
        notifyError('There was a problem uploading this document.')
        return
      }

      if (this.description == '') {
        notifyError('Description is a required field.')
        return
      }

      const payload = {
        workOrderDocumentID: this.workOrderDocumentProp.workOrderDocumentID,
        workOrderID: this.workOrderID,
        description: this.description,
        documentId: this.workOrderDocumentProp.documentID
      }

      //Update
      await workOrderDocumentStore.dispatch('updateWorkOrderDocument', {
        payload: payload,
        done: response => {
          this.loading = false

          if (response && response != undefined) {
            this.description = response.description
          }
        }
      })
    },

    async replaceFile() {
      if (
        this.workOrderID <= 0 ||
        !this.workOrderDocumentProp ||
        this.workOrderDocumentProp == undefined
      ) {
        notifyError('There was a problem uploading this document.')
        return
      }

      if (this.description == '') {
        notifyError('Description is a required field.')
        return
      }

      if (!this.file || this.file == undefined) {
        notifyError('The upload file is a required.')
        return
      }

      this.loading = true

      try {
        if (this.attachment == true) {
          await workOrderDocumentStore
            .dispatch('updateWorkOrderDocumentAttachment', {
              workOrderDocumentID: this.workOrderDocumentProp.workOrderDocumentID,
              file: this.file
            })
            .then(({ returned }) => {
              if (returned && returned != undefined && returned.documentId > 0) {
                this.imageContent = null
                this.base64pdf = null
                this.workOrderDocumentProp.documentID = returned.documentId
                this.attachment = true
                this.loadCurrentDocument()
              }
            })
        } else {
          const payload = {
            file: this.file,
            existingWorkOrderDocumentID: this.workOrderDocumentProp.workOrderDocumentID,
            workOrderID: this.workOrderDocumentProp.workOrderID
          }

          await workOrderDocumentStore
            .dispatch('uploadWorkOrderDocument', {
              payload: payload
            })
            .then(({ returnedfile }) => {
              if (returnedfile) {
                this.uploadedWorkOrderDocumentFile = returnedfile
              }

              this.base64pdf = ''
              if (
                this.uploadedWorkOrderDocumentFile &&
                this.uploadedWorkOrderDocumentFile != undefined
              ) {
                this.imageContent = null
                this.base64pdf = null

                const returned = this.uploadedWorkOrderDocumentFile
                let returnedDocumentId = 0
                try {
                  if (
                    returned &&
                    returned != undefined &&
                    returned.documentId != undefined &&
                    returned.documentId
                  ) {
                    returnedDocumentId = returned.documentId
                  }
                } catch (e) {
                  if (this.isDebug == true)
                    console.debug('returnedDocumentId=' + returnedDocumentId)
                }

                this.workOrderDocumentProp.documentID = returnedDocumentId

                if (this.isDebug == true)
                  console.debug(
                    JSON.stringify(returned) +
                      ', workOrderDocumentProp=' +
                      JSON.stringify(this.workOrderDocumentProp)
                  )

                this.attachment = true
                this.loadCurrentDocument()

                notifyMessage(`Successfully uploaded the document.`)
              } else {
                notifyError('There was a problem uploading this document.')
              }
            })
        }
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    },

    async submitFile() {
      if (this.workOrderID <= 0) {
        notifyError('There was a problem uploading this document.')
        return
      }

      if (this.description == '') {
        notifyError('Description is a required field.')
        return
      }

      this.loading = true

      try {
        if (this.attachment == false) {
          await workOrderDocumentStore
            .dispatch('addWorkOrderDocument', {
              workOrderID: this.workOrderID,
              description: this.description
            })
            .then(async ({ returned }) => {
              if (returned && returned != undefined && returned.workOrderDocumentID > 0) {
                const payload = {
                  file: this.file,
                  existingWorkOrderDocumentID: returned.workOrderDocumentID,
                  workOrderID: returned.workOrderID
                }

                await workOrderDocumentStore
                  .dispatch('uploadWorkOrderDocument', {
                    payload: payload
                  })
                  .then(({ returnedfile }) => {
                    this.base64pdf = ''
                    if (returnedfile && returnedfile != undefined) {
                      if (this.isDebug == true)
                        console.debug(
                          'results.............................=' + JSON.stringify(returnedfile)
                        )

                      this.imageContent = null
                      this.base64pdf = null

                      this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))
                      // auto-close modal
                      $('#clickclose').click()

                      notifyMessage(`Successfully uploaded the document.`)
                    } else {
                      notifyError('There was a problem uploading this document.')
                    }
                  })
              }
            })
        }
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    },

    zoom(direction) {
      const amt = Math.sqrt(2)
      if (direction > 0) {
        this.scale *= amt
      } else {
        this.scale /= amt
      }

      // constrain to min/max
      this.scale = Math.max(0.5, Math.min(4, this.scale))
    }
  }
}
</script>

<style lang="scss">
#work-order-document-modal {
  .modal-h-full {
    left: 50%;
    transform: translate(-50%);
  }

  .doc-viewer {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 130%;
    min-height: 40vh;
    max-height: calc(100vh - 320px);
    border: 1px solid #dbdbdb;

    &.zoom-active {
      cursor: grab;
    }
  }

  .doc-controls {
    margin-top: -1em;
  }
}

#request-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}

.uploader {
  .rectangle {
    width: 244px;
    height: 137px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0 0px;
    border-radius: 4px;
  }
  .Drag-Drop-your-fil {
    width: 192px;
    height: 34px;
    margin: 7px 0 8px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #838383;
  }

  .Drag-Drop-your-fil .text-style-1 {
    font-weight: bold;
  }

  img.Fill-1-Copy-11 {
    width: 24px;
    height: 30px;
    margin: 0 85px 7px 83px;
    object-fit: contain;
  }
}
</style>
