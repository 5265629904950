<template>
  <div class="section">
    <div class="container">
      <div class="is-size-4" v-if="noteViewOnly === true">View Work Order Note</div>
      <div class="is-size-4" v-else-if="buttonText == 'Update'">Update Work Order Note</div>
      <div class="is-size-4" v-else>Create Work Order Note</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="column is-12" v-if="formData.Author && formData.Author != undefined">
                  <valid-input
                    style="width:50px !important;"
                    name="Author"
                    label="Author"
                    disabled="true"
                    type="text"
                    vid="Author"
                    placeholder="Author"
                    spellcheck="true"
                    aria-label="Author"
                    class="is-small"
                    v-model="formData.Author"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important;"
                    :disabled="noteViewOnly"
                    name="Note"
                    label="Note"
                    maxlength="8000"
                    type="textarea"
                    vid="Note"
                    placeholder="Note"
                    spellcheck="true"
                    aria-label="Note"
                    rules="max:8000|required"
                    class="is-small"
                    v-model="formData.note"
                  />
                </div>
                <div class="column is-12">
                  <span class="pb-5 pr-5" v-if="!noteViewOnly">
                    <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                      {{ buttonText }}
                    </button>
                  </span>
                  <span><b-button type="is-primary" @click="closeModal">Close</b-button></span>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { mapState, mapGetters } from 'vuex'
import $ from 'jquery'
import { notifyError } from '@/services/notify'

import { workOrderNoteStore } from '@/services/WorkOrders/WorkOrderNote/store'

// components
import ValidInput from '@/components/inputs/ValidInput'

import moment from 'moment'

let DEFAULT_FORM_VALUES = {
  workOrderID: 0,
  author: '',
  note: ''
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'WorkOrderNoteModal',

  props: {
    workOrderID: Number,
    workOrderNote: Object,
    noteViewOnly: Boolean
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null,
      buttonText: 'Add'
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  mounted() {
    this.loading = true
    if (
      this.workOrderNote &&
      this.workOrderNote.workOrderNoteID != undefined &&
      this.workOrderNote.workOrderNoteID &&
      this.workOrderNote.workOrderNoteID > 0
    ) {
      if (this.isDebug == true)
        console.debug('this.workOrderNote=' + JSON.stringify(this.workOrderNote))
      //this.formData.Author = this.workOrderNote.aspNetName
      this.formData.note = this.workOrderNote.note
      this.buttonText = 'Update'
    } else {
      this.formData = { ...DEFAULT_FORM_VALUES }
      this.buttonText = 'Add'
    }

    this.loading = false
  },

  methods: {
    closeModal() {
      this.$emit('update:toggle', (this.noteToggle = !this.noteToggle))

      // auto-close modal
      $('#clickclose').click()
    },
    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const payload = {
        workOrderNoteID:
          this.workOrderNote &&
          this.workOrderNote != undefined &&
          this.workOrderNote.workOrderNoteID
            ? this.workOrderNote.workOrderNoteID
            : null,
        workOrderID: this.workOrderID && this.workOrderID != undefined ? this.workOrderID : 0,
        note: this.formData.note && this.formData.note != undefined ? this.formData.note : '',
        aspNetUserID:
          this.authUser.aspNetUserID && this.authUser.aspNetUserID != undefined
            ? this.authUser.aspNetUserID
            : ''
      }

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      return payload
    },
    async onFormSubmit() {
      if (
        !this.formData ||
        (this.formData == undefined && this.workOrderID && this.workOrderID > 0)
      ) {
        notifyError('There was a problem submitting your new work order note.')
        return
      }

      if (this.isDebug == true) console.debug('buttonText=' + this.buttonText)

      try {
        if (this.buttonText == 'Update') {
          if (!this.workOrderNote || this.workOrderNote == undefined) {
            notifyError('There was a problem updating your new work order note.')
            return
          }

          const updatePayload = this.getPayload()
          await workOrderNoteStore.dispatch('updateWorkOrderNote', {
            payload: updatePayload,
            done: response => {
              this.loading = false
              this.formData.note =
                response && response != undefined ? response.note : updatePayload.note

              // auto-close modal
              this.$emit('update:noteToggle', (this.noteToggle = false))
              $('#clickclose').click()
              this.loading = false
            }
          })
        } else {
          //Add
          const payload = this.getPayload()
          let validationMessages = ''

          if (payload.note == '') {
            validationMessages = '<div>Please enter a note.</div>'
          }

          if (validationMessages != '') {
            notifyError(validationMessages)
            return
          }

          this.loading = true
          await workOrderNoteStore.dispatch('addWorkOrderNote', {
            workOrderID: payload.workOrderID,
            note: payload.note,
            aspNetUserID: payload.aspNetUserID,
            done: () => {
              // auto-close modal
              this.$emit('update:noteToggle', (this.noteToggle = false))
              $('#clickclose').click()
              this.loading = false
            }
          })
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem saving the work order note.' + e)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
#work-order-note-modal .step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

#work-order-note-modal .b-steps.is-small .steps {
  margin-top: 1rem;
}

#work-order-note-modal .b-steps .steps + .step-content {
  padding-top: 0 !important;
}

#work-order-note-modal .step-navigation {
  display: flex;
  justify-content: flex-end;
}

#work-order-note-modal .my-grid-class {
  height: 400px;
}

#work-order-note-modal tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
