/*
  example:
    import { StorePhoto } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/StorePhotoFile';

    const { successful, documentId } = await StorePhoto ({
      photo,
      params: {
        workOrderRequestID
      }
    });
    if (!successful) {

    }
*/

/*
  example response:

*/
import kms from '@/services/kms'
import _get from 'lodash/get'

const isDebug = false

export async function StorePhoto({ params, photo }) {
  if (isDebug === true) console.debug('in StorePhoto for work order requests...')
  const message = 'A problem occurred and the photo could not be uploaded.'

  try {
    const formData = new FormData()
    formData.append('file', photo)

    const results = await kms.post(
      `/WorkOrderRequests/WorkOrderRequestPhoto/StorePhotoFile?workOrderRequestID=${params.workOrderRequestID}`,
      formData,
      {
        'Content-Type': 'multipart/form-data'
      }
    )

    if (isDebug === true) console.debug({ results })

    const documentId = _get(results, 'documentId', null)

    return {
      successful: true,
      message: '',
      documentId
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    documentId: null,
    message
  }
}
