export const data = function() {
  return {
    isDebug: true,
    loading: false,
    workOrderID: 0,
    workOrder: null,
    tabs: [
      { name: 'workorderDetails', label: 'Update Work Order' },
      { name: 'workorderDocuments', label: 'Documents' },
      { name: 'workorderNotes', label: 'Notes' },
      { name: 'workorderPhotos', label: 'Photos' },
      { name: 'workorderConversations', label: 'Conversations' }
    ],
    styles: {
      mainContainer: {
        minHeight: '1000px'
      }
    },
    transitionName: 'slide-down',
    showUploadModal: false
  }
}
