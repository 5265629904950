export const data = () => ({
  isDebug: true,
  loading: false,
  workOrderID: 0,
  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
