/*
  import { workOrderItem } from '@/services/WorkOrders/UpdateWorkOrder/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { workOrder } = await workOrderItem.dispatch ('getWorkOrderById', {
      workOrderID
    });
  */
  //Work Order by Id
  async getWorkOrderById({}, { workOrderID }) {
    try {
      console.debug('in getWorkOrderById...')
      const workorder = await kms.get(`/WorkOrders/WorkOrder/${workOrderID}`)

      if (isDebug == true) console.debug('getWorkOrderById=' + JSON.stringify(workorder))

      return {
        workorder: workorder
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the work order.`)
      console.error(exception)
    }

    return {
      workorder: {}
    }
  },

  /*
    const { workordertypelist } = await workOrderItem.dispatch ('getWorkOrderTypeList', {
      hoaId
    });
  */
  //Work Order Type List
  async getWorkOrderTypeList({}, hoaID) {
    try {
      const params = {
        hoaID: hoaID
      }

      let { results: workOrderTypes } = await kms.get('/WorkOrders/WorkOrderType/List', { params })

      if (isDebug == true) console.debug('workOrderTypes=' + JSON.stringify(workOrderTypes))

      const workOrderTypeDropDownList = workOrderTypes
        .map(v => ({
          value: v.workOrderTypeID,
          label: v.name ? v.name : ''
        }))
        .filter(f => f.label.length > 0 && f.value > 0)
        .sort((a, b) => a.label - b.label)

      if (isDebug == true)
        console.debug('workOrderTypeDropDownList=' + JSON.stringify(workOrderTypeDropDownList))

      return {
        list: workOrderTypeDropDownList
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the work order type list.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  // Update Work Order
  async updateWorkOrder({ dispatch }, { payload = {}, done }) {
    try {
      if (isDebug === true) console.debug('updateWorkOrder payload=' + JSON.stringify(payload))

      const results = await kms.put(`/WorkOrders/WorkOrder`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderID > 0) {
        notifyMessage(`Successfully updated the work order.`)
        done()
      } else {
        notifyError('There was a problem updating this work order.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this work order.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const workOrderItem = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
