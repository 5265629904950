<template>
  <PageContent :title="$t('workOrdersList.title')">
    <div class="level">
      <div class="level-left"></div>

      <div class="level-right">
        <b-field label="Status" tabindex="0" aria-label="work order status filter">
          <b-radio v-model="statusFilter" name="name" native-value="all" aria-label="show all">
            All
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="name"
            native-value="incomplete"
            aria-label="only show incomplete work orders"
          >
            Incomplete
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="name"
            native-value="complete"
            aria-label="only show complete work orders"
          >
            Complete
          </b-radio>
        </b-field>
      </div>
    </div>

    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
    />
    <Modal :toggle.sync="toggle">
      <WorkOrderModal />
    </Modal>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import WorkOrderModal from '@/components/WorkOrderModal'
import Modal from '@/components/Modal'

import { mapState, mapGetters } from 'vuex'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'WorkOrderList',
  components: {
    PageContent,
    ModernTable,
    WorkOrderModal,
    Modal
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,

  methods,

  mounted: function() {
    data, (this.loading = true)
    this.reload()

    this.loading = false
  },

  watch: {
    statusFilter: 'reloadFilter',
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  i18n: {
    messages: {
      en: {
        workOrdersList: {
          title: 'Work Orders'
        }
      }
    }
  }
}
</script>

<style></style>
