<template>
  <div class="section">
    <div class="container">
      <div class="is-size-4">Complete Work Order</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="column is-12">
                  <b-field label="Completed Date">
                    <b-datepicker
                      tests-id="workorder-complete-date"
                      v-model="formData.completedDate"
                      label="Complete By Date"
                      :show-week-number="showWeekNumber"
                      placeholder="Click to select Completed Date..."
                      icon="calendar-today"
                      trap-focus
                      :required="true"
                      editable
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="is-12 pt-5 pl-3">
                  <valid-input
                    tests-id="workorder-completed-note"
                    id="TestCompletedNoteInput"
                    style="width:50px !important;"
                    name="CompletedNote"
                    label="Completed Notes"
                    type="textarea"
                    vid="CompletedNote"
                    placeholder="Completed Notes"
                    spellcheck="true"
                    aria-label="Completed Notes"
                    rules="max:8000|required"
                    class="is-small"
                    v-model="formData.completedNote"
                  />
                </div>
                <div class="pt-5 pl-3">
                  <button
                    tests-id="workorder-complete-modal-button"
                    type="submit"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                  >
                    {{ buttonText }}
                  </button>
                </div>
                <div class="pb-6"></div>
                <div class="pb-6"></div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { ToastProgrammatic as Toast } from 'buefy'
import { mapState, mapGetters } from 'vuex'
import $ from 'jquery'

import { workOrderItem } from '@/services/WorkOrders/UpdateWorkOrder/store'

// components
import ValidInput from '@/components/inputs/ValidInput'

import moment from 'moment'
const today = moment()

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

let DEFAULT_FORM_VALUES = {
  completedDate: null,
  completedNote: ''
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'WorkOrderCompleteModal',

  props: {
    workOrderID: Number,
    workOrder: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null,
      buttonText: 'Complete',
      showWeekNumber: false
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  mounted() {
    this.loading = true
    if (
      this.workOrder &&
      this.workOrder.workOrderID != undefined &&
      this.workOrder.workOrderID &&
      this.workOrder.workOrderID > 0
    ) {
      if (this.isDebug == true)
        console.debug('this.workOrder completed=' + JSON.stringify(this.workOrder))

      this.formData.completedDate =
        this.workOrder.completionDate != null ? new Date(this.workOrder.completionDate) : null
      this.formData.completedNote = this.workOrder.completionNote
        ? this.workOrder.completionNote
        : ''
    }
    this.loading = false
  },

  methods: {
    closeModal() {
      this.$emit('update:toggle', (this.completeToggle = !this.completeToggle))

      // auto-close modal
      $('#clickclose').click()
    },

    async onFormSubmit() {
      if (
        !this.formData ||
        this.formData == undefined ||
        !this.workOrder ||
        this.workOrder == undefined
      ) {
        notifyError('There was a problem completing this work order.')
        return
      }

      if (this.formData.completedDate && this.formData.completedDate != undefined) {
        if (this.formData.completedDate > today.toDate()) {
          notifyError('The completion date cannot be set to a future date.')
          return
        }

        if (this.workOrder.sentToVendorDate !== undefined && this.workOrder.sentToVendorDate) {
          if (new Date(this.workOrder.sentToVendorDate) > new Date(this.formData.completedDate)) {
            notifyError(
              `The "Sent to Vendor" date cannot be greater than the completion date. Either change the "Sent to Vendor" date, or set this completion date past: ${moment(
                this.workOrder.sentToVendorDate
              ).format('MM-DD-YYYY')}.`
            )
            return
          }
        }
      }

      if (
        this.workOrder.sentToVendorDate !== undefined &&
        this.workOrder.sentToVendorDate &&
        this.workOrder.completeByDate !== undefined &&
        this.workOrder.completeByDate
      ) {
        if (
          new Date(this.formData.sentToVendorDate === today.toDate()) &&
          new Date(this.formData.sentToVendorDate === this.formData.completedDate)
        ) {
          //acceptable
        } else if (
          new Date(this.workOrder.sentToVendorDate) > new Date(this.formData.completeByDate)
        ) {
          notifyError(
            `The "Sent to Vendor" date cannot be greater than the complete by date. Either change the "Sent to Vendor" date, or set the complete by date past: ${moment(
              this.workOrder.sentToVendorDate
            ).format('MM-DD-YYYY')}.`
          )
          return
        }
      }

      try {
        this.workOrder.completionDate =
          this.formData.completedDate && this.formData.completedDate != undefined
            ? this.formData.completedDate
            : null
        this.workOrder.completionNote =
          this.formData.completedNote && this.formData.completedNote != undefined
            ? this.formData.completedNote
            : ''

        if (!this.workOrder.completionDate || !this.workOrder.completionNote) {
          notifyError('Please provide values for both the completion date and completed note.')
          return
        }

        let payload = this.workOrder
        payload.vendor = null
        payload.unit = null
        payload.workOrderType = null

        await workOrderItem.dispatch('updateWorkOrder', {
          payload: payload,
          done: () => {
            this.loading = false
            this.closeModal()
          }
        })
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem completing the work order.' + e)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
#work-order-complete-modal .step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

#work-order-complete-modal .b-steps.is-small .steps {
  margin-top: 1rem;
}

#work-order-complete-modal .b-steps .steps + .step-content {
  padding-top: 0 !important;
}

#work-order-complete-modal .step-navigation {
  display: flex;
  justify-content: flex-end;
}

#work-order-complete-modal .my-grid-class {
  height: 400px;
}

#work-order-complete-modal tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
