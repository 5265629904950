import moment from 'moment'

export const DEFAULT_FORM_VALUES = {
  completeByDate: null,
  workOrderTypeID: 0,
  locationDescription: '',
  description: '',
  detailedDescription: '',
  specialInstructions: ''
}

export function data() {
  return {
    formComplete: false,
    formData: {
      ...DEFAULT_FORM_VALUES
    },
    loading: false,
    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    },
    isDebug: true,
    selectedRow: null,
    buttonText: '',
    openOnFocus: true,
    locationFilterQuery: null,
    vendorFilterQuery: null,
    isFetching: false,
    locationType: null,
    latitude: 0,
    longitude: 0,
    hideMap: false,
    defaultCenterLat: 0,
    defaultCenterLng: 0,
    workOrderRequestID: 0,
    workOrderID: 0,
    accessToken: '',
    data: [],
    vendorData: [],
    typeData: [],
    workOrderTypeList: [],
    vendorDropDownList: [],
    addressDropDownList: [],
    locationTypeList: [],
    tempWorkOrderType: [],
    tempAddress: [],
    showWeekNumber: false,
    newApInvoiceId: 0,
    activeStep: 'create',
    files: [],
    photos: [],
    image: null,
    doc: null,
    documentIds: [],
    previews: [],
    docPreviews: [],
    hideShowMapFlag: false,
    hideShowMapText: 'Show Map',
    locationTypeText: 'Location Type',
    transmit: true
  }
}
