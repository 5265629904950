/*
  import { workOrderPhotoStore } from '@/services/WorkOrders/WorkOrderPhoto/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyMessage, notifyError } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getWorkOrderPhotoList({}, { workOrderID }) {
    console.log('in getWorkOrderPhotoList...')
    try {
      const result = await kms.get('/WorkOrders/WorkOrderPhoto/List', {
        params: {
          workOrderID
        }
      })

      if (isDebug == true) console.debug('getWorkOrderPhotoList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The work order photos list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getWorkOrderPhotoGetFile({}, { params = {} }) {
    try {
      const result = await kms.get(`/WorkOrders/WorkOrderPhoto/GetWorkOrderPhotoFile`, {
        params
      })

      if (isDebug == true) console.debug('getWorkOrderPhotoGetFile=' + result)

      return {
        returned: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the work order photo file.`)
      console.error(exception)
    }

    return {
      returned: null
    }
  },

  // Update Work Order Document
  async updateWorkOrderPhoto({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/WorkOrders/WorkOrderPhoto`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderPhotoID > 0) {
        if (payload.remove && payload.reomove != undefined && payload.remove == true) {
          notifyMessage(`Successfully removed the work order photo.`)
        } else {
          notifyMessage(`Successfully updated the work order photo description.`)
        }
        done()
      } else {
        notifyError('There was a problem updating this work order photo description.')
      }
    } catch (exception) {
      notifyProblem('There was a problem updating this work order photo description.')
      console.error(exception)
    }
  },

  // Update Work Order Document Attachment
  async updateWorkOrderPhotoAttachment({}, payload = {}) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/WorkOrders/WorkOrderPhoto/UpdateAttachment?workOrderPhotoID=${payload.workOrderPhotoID}`

      const updateWorkOrderPhoto = await kms.put(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(updateWorkOrderPhoto))

      return {
        returned: updateWorkOrderPhoto
      }
    } catch (exception) {
      notifyProblem('There was a problem updating this work order photo attachment.')
      console.error(exception)
    }

    return {
      returned: null
    }
  },

  async addWorkOrderPhoto({}, { workOrderID, description }) {
    try {
      const workOrderPhoto = await kms.post(`/WorkOrders/WorkOrderPhoto`, {
        workOrderID,
        description
      })

      if (isDebug == true) console.debug('workOrderPhoto=' + JSON.stringify(workOrderPhoto))

      return {
        returned: workOrderPhoto
      }
    } catch (exception) {
      notifyProblem('There was a problem adding the work order photo.')
      console.error(exception)
    }

    return {
      returned: null
    }
  },

  async uploadWorkOrderPhoto({}, { payload }) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/WorkOrders/WorkOrderPhoto/StoreWorkOrderPhotoFile?workOrderID=${payload.workOrderID}&existingWorkOrderPhotoID=${payload.existingWorkOrderPhotoID}`
      const uploadedWorkOrderPhotoFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug(
          'uploadedWorkOrderPhotoFile with payload=' + JSON.stringify(uploadedWorkOrderPhotoFile)
        )

      return {
        returnedfile: uploadedWorkOrderPhotoFile
      }
    } catch (exception) {
      notifyProblem('There was a problem uploading the work order photo.')
      console.error(exception)
    }

    return {
      returnedfile: null
    }
  },

  async deleteWorkOrderPhoto({ dispatch }, { workOrderPhotoID, done }) {
    try {
      const path = `/WorkOrders/WorkOrderPhoto/${workOrderPhotoID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the work order photo.`)
        done()
      } else {
        notifyError('There was a problem deleting the work order photo.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting the work order photo.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const workOrderPhotoStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
