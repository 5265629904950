import _get from 'lodash/get'
import Button from '@/components/buttons/Button'
import { parseDate } from '@/utilities/Date/parse'
import { workOrderDocumentStore } from '@/services/WorkOrders/WorkOrderDocument/store'

export const methods = {
  confirmDeleteDocument(id, description) {
    this.$buefy.dialog.confirm({
      title: 'Delete Work Order Document',
      message: `Are you sure you want to <b>delete</b> the work order document for: "${description}"?`,
      confirmText: 'Delete Work Order Document',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteDocument(id)
    })
  },

  async deleteDocument(id) {
    await workOrderDocumentStore.dispatch('deleteWorkOrderDocument', {
      workOrderDocumentID: id,
      done: () => {
        this.loading = false
        this.selectedDocument = null
        this.reload()
      }
    })
  },

  processDocument(document) {
    this.documentToggle = true
    this.selectedDocument = document
    this.documentViewOnly = false
  },

  processDocumentUpdate(document) {
    this.toggle = true
    this.selectedDocument = document
    this.documentViewOnly = false
  },

  createDocumentModal() {
    this.selectedDocument = null
    this.toggle = true
    this.documentViewOnly = false
  },

  processDocumentView(document) {
    this.toggle = true
    this.selectedDocument = document
    this.documentViewOnly = true
  },

  determineRows(list) {
    if (list) {
      var dateFormat = 'LL'

      this.documentsList = list.map(i => {
        const tempDate = _get(i, 'createdDate', '')
        const createdDate = parseDate(tempDate, dateFormat)

        var description = _get(i, 'description', '')
        var workOrderDocumentID = _get(i, 'workOrderDocumentID', 0)
        const isAuthenticated = _get(i, 'isAuthenticated', false)

        return {
          description: description,
          createdDate: createdDate,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processDocumentUpdate(i)
              },
              text: 'Edit',
              visible: isAuthenticated == true ? true : false
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeleteDocument(workOrderDocumentID, description)
              },
              text: 'Delete',
              visible: isAuthenticated == true ? true : false
            }
          },
          view: {
            component: Button,
            props: {
              onClick: () => {
                this.processDocumentView(i)
              },
              text: 'View',
              visible: isAuthenticated == false || isAuthenticated == null ? true : false
            }
          }
        }
      })
    }
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async loadDocumentList() {
    workOrderDocumentStore
      .dispatch('getWorkOrderDocumentList', {
        workOrderID: this.workOrderID
      })
      .then(({ list }) => {
        if (list) {
          this.determineRows(list)
        }
      })
  },

  async reload() {
    this.loading = true

    this.documentViewOnly = false

    this.workOrderID = this.$route.params.id || 0

    await this.loadDocumentList()

    this.rows = []

    this.loading = false
  }
}
