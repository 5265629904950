<template>
  <div class="section uploader">
    <div class="container">
      <div class="is-size-4">{{ `${title} Work Order Photo` }}</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="Description"
                    label="Description"
                    type="textarea"
                    vid="Description"
                    placeholder="Description"
                    spellcheck="true"
                    aria-label="Description"
                    rules="max:100|required"
                    class="is-small"
                    v-model="description"
                  />
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
        <div class="box is-8">
          <section>
            <div v-if="imageContent">
              <img :src="imageContent" />
            </div>
            <div class="pt-5" v-if="!photoViewOnly">
              <!-- Show the Upload Form -->
              <b-field>
                <b-upload
                  v-model="file"
                  drag-drop
                  class="rectangle"
                  accept="image/png, image/jpeg, image/jpg"
                >
                  <section class="section">
                    <div class="content is-center">
                      <div>
                        <img
                          class="Fill-1-Copy-11"
                          src="@/assets/images/fill-1-copy-11.svg"
                          alt="Upload"
                        />
                      </div>
                      <div v-if="file">
                        {{ file.name }}
                      </div>
                      <div v-else>
                        <div class="Drag-Drop-your-fil">
                          <div class="text-style-1">Drag & Drop</div>
                          <div>your files here to upload</div>
                        </div>
                      </div>
                    </div>
                  </section>
                </b-upload>
              </b-field>
            </div>

            <div class="pt-5">
              <span class="pr-5" v-if="attachment == false && !workOrderPhotoProp && !photoViewOnly"
                ><b-button type="is-primary" :disabled="!file" @click="submitFile"
                  >Upload</b-button
                ></span
              >
              <span
                class="pr-5"
                v-if="workOrderPhotoProp && workOrderPhotoProp != undefined && !photoViewOnly"
                ><b-button type="is-primary" :disabled="!file" @click="replaceFile"
                  >Replace</b-button
                ></span
              >
              <span v-if="workOrderPhotoProp && !photoViewOnly" class="pt-5 pr-5">
                <b-button type="is-primary" v-if="attachment == true" @click="updateModal"
                  >Update</b-button
                >
              </span>
              <span
                v-show="false"
                v-if="
                  workOrderPhotoProp && attachment == true && this.imageContent && !photoViewOnly
                "
                class="pt-5 pr-5"
              >
                <b-button type="is-primary" @click="removeFile">Remove</b-button>
              </span>
              <span><b-button type="is-primary" @click="closeModal">Close</b-button></span>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ToastProgrammatic as Toast } from 'buefy'
import { mapState } from 'vuex'
import { dragscroll } from 'vue-dragscroll'
import moment from 'moment'
import $ from 'jquery'

import { workOrderPhotoStore } from '@/services/WorkOrders/WorkOrderPhoto/store'

// components
import ValidInput from '@/components/inputs/ValidInput'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

export default {
  name: 'WorkOrderPhotoModal',
  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  directives: { dragscroll },

  props: {
    workOrderID: Number,
    workOrderPhotoProp: Object,
    photoViewOnly: Boolean
  },

  data: function() {
    return {
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      file: null,
      base64pdf: '',
      scale: 1,
      searchTerm: -1,
      imageContent: null,
      description: '',
      attachment: false,
      title: 'Create',
      workOrderPhotoFile: null,
      workOrderPhoto: null,
      uploadedWorkOrderPhotoFile: null
    }
  },

  mounted() {
    this.description = ''
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    reload() {
      this.reload()
    }
  },

  methods: {
    reload() {
      if (this.isDebug == true)
        console.debug('workOrderPhotoProp=' + JSON.stringify(this.workOrderPhotoProp))

      if (this.isDebug == true)
        console.debug('reload workOrderPhotoProp=' + this.workOrderPhotoProp)

      if (
        this.workOrderPhotoProp &&
        this.workOrderPhotoProp.workOrderPhotoID &&
        this.workOrderPhotoProp.workOrderPhotoID > 0
      ) {
        this.title = 'Update'

        this.description = this.workOrderPhotoProp.description
        if (this.workOrderPhotoProp.documentID && this.workOrderPhotoProp.documentID != undefined) {
          this.attachment = true
          this.loadCurrentPhoto()
        }
      } else {
        this.title = 'Create'
        this.description = ''
        this.attachment = false
      }
    },

    async loadCurrentPhoto() {
      if (
        !this.workOrderPhotoProp ||
        !this.workOrderPhotoProp.documentID ||
        this.workOrderPhotoProp.documentID <= 0
      ) {
        if (this.isDebug == true) console.debug('loadCurrentPhoto issue')
        return
      }

      if (this.isDebug == true) console.debug('loadCurrentPhoto...')

      this.searchTerm = this.workOrderPhotoProp.documentFileName.indexOf('.png')

      if (this.searchTerm == -1) {
        this.searchTerm = this.workOrderPhotoProp.documentFileName.indexOf('.jpeg')
      }

      if (this.searchTerm == -1) {
        this.searchTerm = this.workOrderPhotoProp.documentFileName.indexOf('.jpg')
      }

      try {
        const params = {
          workOrderPhotoID: this.workOrderPhotoProp.workOrderPhotoID,
          asBase64: true
        }

        if (this.isDebug == true)
          console.debug('searchTerm=' + this.searchTerm + ', params=' + JSON.stringify(params))

        await workOrderPhotoStore
          .dispatch('getWorkOrderPhotoGetFile', {
            params: params
          })
          .then(({ returned }) => {
            if (returned) {
              this.workOrderPhotoFile = returned
            }

            if (this.isDebug == true)
              console.debug(
                ' - params' +
                  JSON.stringify(params) +
                  ' - this.workOrderPhotoFile=' +
                  JSON.stringify(this.workOrderPhotoFile)
              )
            if (this.workOrderPhotoFile && this.workOrderPhotoFile != undefined) {
              if (this.isDebug == true)
                console.debug(
                  'image or pdf............this.searchTerm:' +
                    this.searchTerm +
                    '............' +
                    this.workOrderPhotoFile
                )

              this.imageContent = this.workOrderPhotoFile
            }
          })
      } catch (e) {
        if (this.isDebug == true) console.debug(e)
      }
    },

    closeModal() {
      this.$emit('update:toggle', (this.photoToggle = !this.photoToggle))

      // auto-close modal
      $('#clickclose').click()
    },

    async updateModal() {
      if (
        this.workOrderID <= 0 ||
        !this.workOrderPhotoProp ||
        this.workOrderPhotoProp == undefined
      ) {
        notifyError('There was a problem uploading this photo.')
        return
      }

      if (this.description == '') {
        notifyError('Description is a required field.')
        return
      }

      const payload = {
        workOrderPhotoID: this.workOrderPhotoProp.workOrderPhotoID,
        workOrderID: this.workOrderID,
        description: this.description,
        documentId: this.workOrderPhotoProp.documentID
      }

      //Update
      await workOrderPhotoStore.dispatch('updateWorkOrderPhoto', {
        payload: payload,
        done: response => {
          this.loading = false

          if (response && response != undefined) {
            this.description = response.description
          }
        }
      })
    },

    async removeFile() {
      if (this.workOrderPhotoProp && this.workOrderPhotoProp != undefined) {
        try {
          if (
            this.workOrderID <= 0 ||
            !this.workOrderPhotoProp ||
            this.workOrderPhotoProp == undefined
          ) {
            notifyError('There was a problem removing this photo.')
            return
          }

          if (this.description == '') {
            notifyError('Description is a required field.')
            return
          }

          const payload = {
            workOrderPhotoID: this.workOrderPhotoProp.workOrderPhotoID,
            workOrderID: this.workOrderID,
            description: this.description,
            documentId: null
          }

          //Update
          await workOrderPhotoStore.dispatch('updateWorkOrderPhoto', {
            payload: payload,
            done: () => {
              this.loading = false
              this.workOrderPhotoProp.documentID = null
              this.base64pdf = null
              this.imageContent = null
              this.attachment = false
              this.reload()
            }
          })
        } catch (e) {
          notifyError(e)
        }
        this.loading = false
      } else {
        notifyError('There was a problem removing this photo.')
      }
    },

    async replaceFile() {
      if (
        this.workOrderID <= 0 ||
        !this.workOrderPhotoProp ||
        this.workOrderPhotoProp == undefined
      ) {
        notifyError('There was a problem uploading this photo.')
        return
      }

      if (this.description == '') {
        notifyError('Description is a required field.')
        return
      }

      if (!this.file || this.file == undefined) {
        notifyError('The upload file is a required.')
        return
      }

      this.loading = true

      try {
        if (this.attachment == true) {
          await workOrderPhotoStore
            .dispatch('updateWorkOrderPhotoAttachment', {
              workOrderPhotoID: this.workOrderPhotoProp.workOrderPhotoID,
              file: this.file
            })
            .then(({ returned }) => {
              if (
                returned &&
                returned != undefined &&
                returned.documentId != undefined &&
                returned.documentId > 0
              ) {
                this.imageContent = null
                this.base64pdf = null
                this.workOrderPhotoProp.documentID = returned.documentId
                this.attachment = true
                this.loadCurrentPhoto()
              }
            })
        } else {
          const payload = {
            file: this.file,
            existingWorkOrderPhotoID: this.workOrderPhotoProp.workOrderPhotoID,
            workOrderID: this.workOrderPhotoProp.workOrderID
          }

          await workOrderPhotoStore
            .dispatch('uploadWorkOrderPhoto', {
              payload: payload
            })
            .then(({ returnedfile }) => {
              if (returnedfile) {
                this.uploadedWorkOrderPhotoFile = returnedfile
              }

              this.base64pdf = ''
              if (this.uploadedWorkOrderPhotoFile && this.uploadedWorkOrderPhotoFile != undefined) {
                this.imageContent = null
                this.base64pdf = null

                const returned = this.uploadedWorkOrderPhotoFile
                let returnedDocumentId = 0
                try {
                  if (
                    returned &&
                    returned != undefined &&
                    returned.documentId != undefined &&
                    returned.documentId
                  ) {
                    returnedDocumentId = returned.documentId
                  }
                } catch (e) {
                  if (this.isDebug == true)
                    console.debug('returnedDocumentId=' + returnedDocumentId)
                }

                this.workOrderPhotoProp.documentID = returnedDocumentId

                if (this.isDebug == true)
                  console.debug(
                    JSON.stringify(returned) +
                      ', workOrderPhotoProp=' +
                      JSON.stringify(this.workOrderPhotoProp)
                  )

                this.attachment = true
                this.loadCurrentPhoto()

                notifyMessage(`Successfully uploaded the photo.`)
              } else {
                notifyError('There was a problem uploading this photo.')
              }
            })
        }
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    },

    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const payload = {
        workOrderPhotoID:
          this.workOrderPhoto &&
          this.workOrderPhoto != undefined &&
          this.workOrderPhoto.workOrderPhotoID
            ? this.workOrderPhoto.workOrderPhotoID
            : null,
        workOrderID: this.workOrderID && this.workOrderID != undefined ? this.workOrderID : 0,
        description:
          this.formData.description && this.formData.description != undefined
            ? this.formData.description
            : ''
      }

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      return payload
    },

    async submitFile() {
      if (this.workOrderID <= 0) {
        notifyError('There was a problem uploading this photo.')
        return
      }

      if (this.description == '') {
        notifyError('Description is a required field.')
        return
      }

      this.loading = true

      try {
        if (this.attachment == false) {
          await workOrderPhotoStore
            .dispatch('addWorkOrderPhoto', {
              workOrderID: this.workOrderID,
              description: this.description
            })
            .then(async ({ returned }) => {
              if (returned && returned != undefined && returned.workOrderPhotoID > 0) {
                const payload = {
                  file: this.file,
                  existingWorkOrderPhotoID: returned.workOrderPhotoID,
                  workOrderID: this.workOrderID
                }

                await workOrderPhotoStore
                  .dispatch('uploadWorkOrderPhoto', {
                    payload: payload
                  })
                  .then(({ returnedfile }) => {
                    this.base64pdf = ''
                    if (returnedfile && returnedfile != undefined) {
                      if (this.isDebug == true)
                        console.debug(
                          'results.............................=' + JSON.stringify(returnedfile)
                        )

                      this.imageContent = null
                      this.base64pdf = null

                      this.$emit('update:toggle', (this.photoToggle = !this.photoToggle))
                      // auto-close modal
                      $('#clickclose').click()

                      notifyMessage(`Successfully uploaded the photo.`)
                    } else {
                      notifyError('There was a problem uploading this photo.')
                    }
                  })
              }
            })
        }
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    },

    zoom(direction) {
      const amt = Math.sqrt(2)
      if (direction > 0) {
        this.scale *= amt
      } else {
        this.scale /= amt
      }

      // constrain to min/max
      this.scale = Math.max(0.5, Math.min(4, this.scale))
    }
  }
}
</script>

<style lang="scss">
#work-order-photo-modal .modal-h-full {
  left: 50%;
  transform: translate(-50%);
}

#request-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}

#work-order-photo-modal .doc-viewer {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 130%;
  min-height: 40vh;
  max-height: calc(100vh - 320px);
  border: 1px solid #dbdbdb;

  &.zoom-active {
    cursor: grab;
  }
}

#work-order-photo-modal .doc-controls {
  margin-top: -1em;
}

.uploader {
  .rectangle {
    width: 244px;
    height: 137px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0 0px;
    border-radius: 4px;
  }
  .Drag-Drop-your-fil {
    width: 192px;
    height: 34px;
    margin: 7px 0 8px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #838383;
  }

  .Drag-Drop-your-fil .text-style-1 {
    font-weight: bold;
  }

  img.Fill-1-Copy-11 {
    width: 24px;
    height: 30px;
    margin: 0 85px 7px 83px;
    object-fit: contain;
  }
}
</style>
