/*
  import { workOrderDocumentStore } from '@/services/WorkOrders/WorkOrderDocument/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyMessage, notifyError } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getWorkOrderDocumentList({}, { workOrderID }) {
    console.log('in getWorkOrderDocumentList...')
    try {
      const result = await kms.get('/WorkOrders/WorkOrderDocument/List', {
        params: {
          workOrderID
        }
      })

      if (isDebug == true) console.debug('getWorkOrderDocumentList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The work order documents list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getWorkOrderDocumentGetFile({}, { params = {} }) {
    try {
      const result = await kms.get(`/WorkOrders/WorkOrderDocument/GetWorkOrderDocumentFile`, {
        params
      })

      if (isDebug == true) console.debug('getWorkOrderDocumentGetFile=' + result)

      return {
        returned: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the work order document file.`)
      console.error(exception)
    }

    return {
      returned: null
    }
  },

  async deleteWorkOrderDocument({ dispatch }, { workOrderDocumentID, done }) {
    try {
      const path = `/WorkOrders/WorkOrderDocument/${workOrderDocumentID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the work order document.`)
        done()
      } else {
        notifyError('There was a problem deleting the work order document.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting the work order document.`)
      console.error(exception)
    }
  },

  // Update Work Order Document
  async updateWorkOrderDocument({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/WorkOrders/WorkOrderDocument`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderDocumentID > 0) {
        if (payload.remove && payload.reomove != undefined && payload.remove == true) {
          notifyMessage(`Successfully removed the work order document.`)
        } else {
          notifyMessage(`Successfully updated the work order document description.`)
        }
        done()
      } else {
        notifyError('There was a problem updating this work order document description.')
      }
    } catch (exception) {
      notifyProblem('There was a problem updating this work order document description.')
      console.error(exception)
    }
  },

  // Update Work Order Document Attachment
  async updateWorkOrderDocumentAttachment({}, payload = {}) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/WorkOrders/WorkOrderDocument/UpdateAttachment?workOrderDocumentID=${payload.workOrderDocumentID}`

      const updateWorkOrderDocument = await kms.put(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(updateWorkOrderDocument))

      return {
        returned: updateWorkOrderDocument
      }
    } catch (exception) {
      notifyProblem('There was a problem updating this work order document attachment.')
      console.error(exception)
    }

    return {
      returned: null
    }
  },

  async addWorkOrderDocument({}, { workOrderID, description }) {
    try {
      const workOrderDocument = await kms.post(`/WorkOrders/WorkOrderDocument`, {
        workOrderID,
        description
      })

      if (isDebug == true) console.debug('workOrderDocument=' + JSON.stringify(workOrderDocument))

      return {
        returned: workOrderDocument
      }
    } catch (exception) {
      notifyProblem('There was a problem adding the work order document.')
      console.error(exception)
    }

    return {
      returned: null
    }
  },

  async uploadWorkOrderDocument({}, { payload }) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/WorkOrders/WorkOrderDocument/StoreWorkOrderDocumentFile?workOrderID=${payload.workOrderID}&existingWorkOrderDocumentID=${payload.existingWorkOrderDocumentID}`
      const uploadedWorkOrderDocumentFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug(
          'uploadedWorkOrderDocumentFile with payload=' +
            JSON.stringify(uploadedWorkOrderDocumentFile)
        )

      return {
        returnedfile: uploadedWorkOrderDocumentFile
      }
    } catch (exception) {
      notifyProblem('There was a problem uploading the work order document.')
      console.error(exception)
    }

    return {
      returnedfile: null
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const workOrderDocumentStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
