<template>
  <div v-show="hideMap && (hideMap === 'true' || hideMap === true) ? false : true">
    <span>
      <div>
        <b-radio v-model="rtoggle" name="satellite" native-value="satellite" aria-label="satellite">
          satellite
        </b-radio>
        <b-radio v-model="rtoggle" name="roadmap" native-value="roadmap" aria-label="roadmap">
          road map
        </b-radio>
        <b-radio v-model="rtoggle" name="hybrid" native-value="hybrid" aria-label="hybrid">
          hybrid
        </b-radio>
        <b-radio v-model="rtoggle" name="terrain" native-value="terrain" aria-label="terrain">
          terrain
        </b-radio>

        <button
          v-if="!readOnlyMap && (!showUpload || (showUpload && showUpload === false))"
          type="button"
          class="button is-text is-small"
          :style="{ verticalAlign: 'super' }"
          @click.prevent="exportStaticImage()"
        >
          Upload Marker Image
        </button>

        <button
          v-if="!readOnlyMap"
          type="button"
          class="button is-text is-small"
          :style="{ verticalAlign: 'super' }"
          @click.prevent="resetPinMarker()"
        >
          Reset Pin Marker
        </button>
        <button
          v-if="!readOnlyMap"
          type="button"
          class="button is-text is-small"
          :style="{ verticalAlign: 'super' }"
          @click.prevent="fitBounds()"
        >
          Fit into Boundary
        </button>
      </div>
      <div title="Click save below to save your location." id="map"></div>
      <div v-show="false" id="coordinates" class="coordinates"></div>
      <div v-show="true" class="columns is-12 pt-5">
        <div class="column is-6">
          <input type="hidden" id="formLatitude" />
        </div>
        <div class="column is-6">
          <input type="hidden" id="formLongitude" />
        </div>
      </div>
    </span>
    <b-field label="Address Search">
      <GmapAutocomplete style="width:700px" @place_changed="setPlace" />
    </b-field>
    <GmapMap
      :title="infoOptions.content"
      ref="mapRef"
      :center="{ lat: myLat, lng: myLong }"
      :zoom="zoomLevel"
      maxZoom="32"
      :map-type-id="selectedMapStyle"
      style="width: 800px; height: 400px"
      :restriction="boundsRestriction"
    >
      <GmapInfoWindow
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
        style="width:300px"
      >
      </GmapInfoWindow>

      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="toggleInfoWindow(m, index)"
        @drag="updateMarker($event.latLng)"
      />
    </GmapMap>
  </div>
</template>

<script>
import { hoaUtility } from '@/services/Hoa/store'
import { mapState } from 'vuex'
import $ from 'jquery'
import { notifyError, notifyMessage } from '@/services/notify'
import { StoreMultiplePhotos } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/procedures/StoreMultiplePhotos'
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'GoogleMapComponent',
  components: {},
  props: {
    mapStyle: String,
    accessToken: String,
    longitude: Number,
    latitude: Number,
    defailtLongitude: Number,
    defaultLatitude: Number,
    zoomLevel: Number,
    workOrderRequestId: Number,
    workOrderId: Number,
    showUpload: Boolean,
    reloadParent: Function,
    returnMarkerAddress: Function,
    hideMap: Boolean,
    readOnlyMap: {
      default: false,
      type: Boolean
    },
    allowGeocodingOnMarker: {
      default: false,
      type: Boolean
    },
    vendorHoaId: Number
  },
  data() {
    return {
      isDebug: true,
      hoa: null,
      selectedMapStyle: '',
      myLat: 0,
      myLong: 0,
      rtoggle: 'satellite',
      markers: [],
      infoWindowPos: null,
      infoWinOpen: false,
      currentPlace: null,
      boundsRestriction: null,
      placeAddress: '',
      infoOptions: {
        content: 'Click the save button, below, to save this location.',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -10
        }
      },
      currentMidx: null
    }
  },
  created() {},
  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    google: gmapApi
  },
  watch: {
    latitude() {
      this.refresh()
    },
    rtoggle() {
      console.debug('rtoggle changed...')
      this.selectedMapStyle = this.rtoggle
    }
  },
  mounted() {
    this.selectedMapStyle = 'satellite'

    this.refresh()
  },
  methods: {
    async refresh() {
      if (this.isDebug === true)
        console.debug(
          `accessToken map=, long/lat ${this.myLong},${this.myLat}, zoom=${this.zoomLevel}`
        )

      await this.loadHoaBoundsCoords()

      if (this.accessToken && this.accessToken !== undefined) {
        if (
          this.hoa &&
          this.hoa !== undefined &&
          this.hoa.westmostLongitude &&
          this.hoa.westmostLongitude !== undefined &&
          this.hoa.southmostLatitude &&
          this.hoa.southmostLatitude !== undefined &&
          this.hoa.eastmostLongitude &&
          this.hoa.eastmostLongitude !== undefined &&
          this.hoa.northmostLatitude &&
          this.hoa.northmostLatitude !== undefined
        ) {
          console.debug('in map lng lat...')

          this.myLat =
            this.latitude && this.latitude !== 0
              ? this.latitude
              : (this.hoa.northmostLatitude + this.hoa.southmostLatitude) / 2
          this.myLong =
            this.longitude && this.longitude !== 0
              ? this.longitude
              : (this.hoa.eastmostLongitude + this.hoa.westmostLongitude) / 2

          $('#formLongitude').val(this.myLong)
          $('#formLongitude').text(this.myLong)
          $('#formLatitude').val(this.myLat)
          $('#formLatitude').text(this.myLat)

          this.boundsRestriction = {
            latLngBounds: {
              north: this.hoa.northmostLatitude,
              south: this.hoa.southmostLatitude,
              east: this.hoa.eastmostLongitude,
              west: this.hoa.westmostLongitude
            },
            strictBounds: false
          }

          this.$refs.mapRef.$mapPromise.then(map => {
            map.panTo({ lat: this.myLat, lng: this.myLong })
            map.fitBounds({
              north: this.hoa.northmostLatitude,
              south: this.hoa.southmostLatitude,
              east: this.hoa.eastmostLongitude,
              west: this.hoa.westmostLongitude
            })
            map.maxZoom = 40
          })

          this.markers = []
          this.markers.push({
            position: { lat: this.myLat, lng: this.myLong },
            bounds: {
              north: this.hoa.northmostLatitude,
              south: this.hoa.southmostLatitude,
              east: this.hoa.eastmostLongitude,
              west: this.hoa.westmostLongitude
            }
          })
        }
      } else {
        if (this.isDebug === true) console.debug('map else...')
      }
    },

    async setPlace(place) {
      try {
        this.currentPlace = place
        console.debug('setPlace...' + JSON.stringify(place))

        if (place && place.geometry && place.geometry.location) {
          const placeLat = place.geometry.location.lat()
          const placeLng = place.geometry.location.lng()

          this.myLat = placeLat
          this.myLong = placeLng

          $('#formLongitude').val(this.myLong)
          $('#formLongitude').text(this.myLong)
          $('#formLatitude').val(this.myLat)
          $('#formLatitude').text(this.myLat)

          this.markers = []

          this.markers.push({
            position: { lat: placeLat, lng: placeLng }
          })

          this.placeAddress = `${place.formatted_address.replace(', USA', '')}`
          console.debug('this.placeAddress=' + this.placeAddress)

          if (this.allowGeocodingOnMarker && this.allowGeocodingOnMarker == true) {
            this.returnMarkerAddress(this.placeAddress)
          }
        }
      } catch (ex) {
        console.debug(ex.message)
      }
    },

    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        console.debug('geolocation=' + position.coords.latitude + ', ' + position.coords.longitude)
      })
    },

    async updateMarker(location) {
      $('#formLongitude').val(location.lng())
      $('#formLongitude').text(location.lng())
      $('#formLatitude').val(location.lat())
      $('#formLatitude').text(location.lat())

      if (this.allowGeocodingOnMarker && this.allowGeocodingOnMarker == true) {
        await this.getStreetAddressFrom(location.lat(), location.lng())
      }
    },

    async getStreetAddressFrom(lat, lng) {
      await hoaUtility.dispatch('retrieveGoogleAddress', {
        lat,
        lng,
        done: ({ result }) => {
          if (result) {
            this.returnMarkerAddress(result.address)
          }
        }
      })
    },

    generateBounds() {
      console.debug('LatLngBounds...')
      if (this.google && this.google.maps) {
        const sw = this.google.maps.LatLng(this.hoa.southmostLatitude, this.hoa.westmostLongitude)
        const ne = this.google.maps.LatLng(this.hoa.northmostLatitude, this.hoa.eastmostLongitude)
        const bounds = new this.google.maps.LatLngBounds(sw, ne)
        for (let m of this.markers) {
          bounds.extend(m.position)
        }
        this.$refs.mapRef.$mapPromise.then(map => {
          map.fitBounds(bounds)
        })
        this.$refs.mapRef.$gmapDefaultResizeBus.$emit('resize')
      } else {
        console.debug('this.google.maps is null')
      }
    },

    fitBounds() {
      try {
        var b = new this.google.maps.LatLngBounds()

        b.extend({
          lat: this.hoa.southmostLatitude,
          lng: this.hoa.westmostLongitude
        })
        b.extend({
          lat: this.hoa.northmostLatitude,
          lng: this.hoa.eastmostLongitude
        })

        this.$refs.mapRef.fitBounds(b)
      } catch (ex) {
        console.debug(ex.message)
      }
    },

    toggleInfoWindow: function(marker, idx) {
      try {
        this.infoWindowPos = marker.position
        //check if its the same marker that was selected if yes toggle
        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true
          this.currentMidx = idx
        }
      } catch (ex) {
        console.debug(ex.message)
      }
    },

    async loadHoaBoundsCoords() {
      console.debug(
        'map loadHoaBoundsCoords...' + this.hoaId && this.hoaId > 0
          ? this.hoaId
          : this.vendorHoaId && this.vendorHoaId > 0
          ? this.vendorHoaId
          : 0
      )

      await hoaUtility
        .dispatch('getHoaById', {
          hoaID:
            this.hoaId && this.hoaId > 0
              ? this.hoaId
              : this.vendorHoaId && this.vendorHoaId > 0
              ? this.vendorHoaId
              : 0
        })
        .then(({ result }) => {
          if (result) {
            this.hoa = result

            if (!this.hoa.westmostLongitude || this.hoa.westmostLongitude === undefined) {
              this.hoa.westmostLongitude = this.longitude + 10
            }

            if (!this.hoa.eastmostLongitude || this.hoa.eastmostLongitude === undefined) {
              this.hoa.eastmostLongitude = this.longitude - 10
            }

            if (!this.hoa.southmostLatitude || this.hoa.southmostLatitude === undefined) {
              this.hoa.southmostLatitude = this.latitude + 10
            }

            if (!this.hoa.northmostLatitude || this.hoa.northmostLatitude === undefined) {
              this.hoa.northmostLatitude = this.latitude - 10
            }

            if (this.isDebug === true) {
              console.debug('hoa bounds=' + JSON.stringify(this.hoa, null, 2))
            }
          }
        })
    },

    async resetPinMarker() {
      this.myLat = (this.hoa.northmostLatitude + this.hoa.southmostLatitude) / 2
      this.myLong = (this.hoa.eastmostLongitude + this.hoa.westmostLongitude) / 2

      $('#formLongitude').val(this.myLong)
      $('#formLongitude').text(this.myLong)
      $('#formLatitude').val(this.myLat)
      $('#formLatitude').text(this.myLat)

      this.markers = []

      this.markers.push({
        position: { lat: this.myLat, lng: this.myLong }
      })

      if (this.isDebug === true) console.debug(`my long/lat ${this.myLong},${this.myLat}`)
    },

    async exportStaticImage() {
      console.debug('exportStaticImage...')
      let lng = $('#formLongitude').val()
      let lat = $('#formLatitude').val()

      if (!lng) {
        lng = this.myLong
      }

      if (!lat) {
        lat = this.myLat
      }

      let url = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=19&markers=color:red%7C${lat},${lng}&size=400x400&maptype=satellite`

      const payload = {
        url: url,
        signatureOnly: true
      }

      await hoaUtility.dispatch('retrieveGoogleMapSignature', {
        payload,
        done: async ({ result }) => {
          if (result) {
            console.debug('signature result=' + result)
            const signature = result.signature
            let exportLink = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=19&markers=color:red%7C${lat},${lng}&size=400x400&maptype=satellite&key=${this.accessToken}&signature=${signature}`

            //console.debug('exportLink=' + exportLink)

            this.signature = ''

            if (this.workOrderRequestId && this.workOrderRequestId !== undefined) {
              try {
                const returnFile = await this.convertLinkToJsFile(exportLink)

                if (this.workOrderRequestId > 0) await this.addPhotos(returnFile)

                if (this.workOrderRequestId > 0) {
                  this.reloadParent()
                } else {
                  this.reloadParent(returnFile)
                }
              } catch (ex) {
                console.debug('ex=' + ex.message)
              }
            } else if (this.workOrderId && this.workOrderId !== undefined) {
              if (this.isDebug === true) console.debug('workOrderId=' + this.workOrderId)
              try {
                const returnFile = await this.convertLinkToJsFile(exportLink)
                this.reloadParent(returnFile)
              } catch (ex) {
                console.debug('ex=' + ex.message)
              }
            }
          }
        }
      })
    },

    refreshParentWorkOrderRequestDetails(id) {
      if (id > 0) {
        this.$router.push({
          path: `/workorderpromotetabs/${id}/workorderRequestPromote#tab`,
          params: {
            id: id
          }
        })
      }
    },

    async convertLinkToJsFile(exportLink) {
      const response = await fetch(exportLink)
      const blob = await response.blob()
      console.debug(`blob type= ${blob.type}`)
      const file = new File([blob], `image.png`, { type: 'image/png' })
      return file
    },

    async addPhotos(item) {
      if (this.isDebug === true) console.debug(item)

      let items = []

      items.push(item)

      const { problems, message } = await StoreMultiplePhotos({
        photos: items,
        params: {
          workOrderRequestID: this.workOrderRequestId
        }
      })
      if (problems >= 1) {
        notifyError(message)
      } else {
        notifyMessage(
          'The marker image has been successfully uploaded to this request and can be previewed below'
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
