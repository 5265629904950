import _get from 'lodash/get'

export const watch = {
  hoaId() {
    this.reload()
  },
  $route: function(to, from) {
    const toIndex = _get(to, ['params', 'index'], 1)
    const fromIndex = _get(from, ['params', 'index'], 1)

    this.transitionName = toIndex < fromIndex ? 'slide-up' : 'slide-down'
  }
}
