import moment from 'moment'

var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  isDebug: true,
  toggle: false,
  loading: false,
  selectedWorkOrder: null,
  workOrders: [],
  statusFilter: 'all',
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: true
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'hoa',
      label: 'Hoa',
      aria: 'Hoa',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'type',
      label: 'Type',
      aria: 'Type',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'title',
      label: 'Title',
      aria: 'Title',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'description',
      label: 'Description',
      aria: 'Description',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'address',
      label: 'Address',
      aria: 'Address',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'dateCreated',
      date: true,
      label: 'Date Created',
      aria: 'Date Created',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'dateCompleted',
      date: true,
      label: 'Date Completed',
      aria: 'Date Completed',
      width: '10%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
