/*
  import { workOrderList } from '@/services/WorkOrders/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'

import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getAccessToken({}, { credentialsName }) {
    try {
      console.debug('in getAccessToken...')
      const credentials = await kms.get(`/Support/ThirdPartyCredentials/GetCredentialsSingle`, {
        params: {
          credentialsName
        }
      })

      if (isDebug == true) console.debug('getAccessToken=')

      return {
        result: credentials
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the result.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async loadLocationTypes({}) {
    try {
      const myList = [
        {
          value: 'My Property',
          label: 'My Property'
        },
        {
          value: 'Common Area',
          label: 'Common Area'
        }
      ]

      if (myList) {
        myList.unshift({
          value: '<-Select a Location Type->',
          label: '<-Select a Location Type->'
        })
      }

      return {
        list: myList
      }
    } catch (exception) {
      notifyProblem(`The Location Types could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async loadLocationTypesManagement({}) {
    try {
      const myList = [
        {
          value: 'My Property',
          label: 'Property'
        },
        {
          value: 'Common Area',
          label: 'Common Area'
        }
      ]

      if (myList) {
        myList.unshift({
          value: '<-Select a Location Type->',
          label: '<-Select a Location Type->'
        })
      }

      return {
        list: myList
      }
    } catch (exception) {
      notifyProblem(`The Location Types could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  /*
    const { list } = await workOrderList.dispatch ('getWorkOrderList', {
      hoaId
    });
  */
  async getWorkOrderList({}, { hoaId, unitID }) {
    console.log('in getWorkOrderList...')
    try {
      const result = await kms.get('/WorkOrders/WorkOrder/ListBasic', {
        params: {
          hoaId,
          unitID: unitID ? unitID : null
        }
      })

      if (isDebug == true) console.debug('getWorkOrderList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The work orders list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async deleteWorkOrder({ dispatch }, { workOrderID, done }) {
    try {
      const path = `/WorkOrders/WorkOrder/${workOrderID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the work order.`)
        done()
      } else {
        notifyError('There was a problem deleting the work order.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this work order.`)
      console.error(exception)
    }
  },

  async cancelWorkOrder({ dispatch }, { workOrderID, cancelledReason, done }) {
    try {
      const path = `/WorkOrders/WorkOrder/CancelWorkOrder`

      const results = await kms.put(path, {
        workOrderID,
        cancelledReason,
        cancelledByAspNetUserID: 'stub'
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results !== undefined && results && results.workOrderID) {
        notifyMessage(`Successfully cancelled the work order.`)
        done()
      } else {
        notifyError('There was a problem cancelling the work order.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem cancelling this work order.`)
      console.error(exception)
    }
  },

  /*
  
  */
  async addWorkOrder(
    { dispatch },
    {
      workOrderTypeID,
      sourceWorkOrderRequestID,
      vendorID,
      hoaID,
      unitID,
      locationDescription,
      description,
      detailedDescription,
      specialInstructions,
      latitude,
      longitude,
      locationType,
      completeByDate,
      done
    }
  ) {
    try {
      const results = await kms.post(`/WorkOrders/WorkOrder`, {
        workOrderTypeID,
        sourceWorkOrderRequestID,
        vendorID,
        hoaID,
        unitID,
        locationDescription,
        description,
        detailedDescription,
        specialInstructions,
        latitude,
        longitude,
        locationType,
        completeByDate
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderID > 0) {
        notifyMessage(`Successfully added work order.`)
        done({ details: results })
        return {
          details: results
        }
      } else {
        notifyError('There was a problem adding the new work order.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this work order.`)
      console.error(exception)
    }

    return {
      details: {}
    }
  },

  /*
    await workOrderList.dispatch('transmitNotification', {
        workOrderID: id,
        done: ({ details }) => {
          console.debug('details=' + details)
          this.loading = false
        }
      })
  */
  async transmitNotification({ dispatch }, { workOrderID, done }) {
    try {
      const results = await kms.post(
        `/WorkOrders/WorkOrder/TransmitNotificationToVendor?workOrderID=${workOrderID}`,
        {
          workOrderID
        }
      )

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results && results === true) {
        notifyMessage(`Successfully transmitted work order notification.`)
        done({ details: results })
        return {
          details: results
        }
      } else {
        notifyError('There was a problem transmitting the work order notification.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem transmitting the work order notification.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const workOrderList = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
