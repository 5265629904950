<template>
  <PageContentLayoutOnly headerColor="#fafafa">
    <div class="notification headerColor" v-if="formData">
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="columns">
                  <div class="column is-6">
                    <label class="label">Location / Unit</label>
                    <valid-input
                      style="width:50px !important;"
                      name="fullAddress"
                      type="text"
                      vid="fullAddress"
                      placeholder="Location / Unit"
                      spellcheck="true"
                      aria-label="Location / Unit"
                      class="is-small"
                      v-model="formData.fullAddress"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-6">
                    <b-field label="Complete By Date">
                      <b-datepicker
                        v-model="formData.completeByDate"
                        label="Complete By Date"
                        :show-week-number="showWeekNumber"
                        icon="calendar-today"
                        trap-focus
                        editable
                        :disabled="true"
                      ></b-datepicker>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="vendor"
                      label="Vendor"
                      type="text"
                      vid="vendor"
                      placeholder="Vendor"
                      spellcheck="true"
                      aria-label="Vendor"
                      class="is-small"
                      v-model="formData.vendor"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-6">
                    <b-field label="Completed Date">
                      <b-datepicker
                        v-model="formData.completedDate"
                        label="Complete By Date"
                        :show-week-number="showWeekNumber"
                        icon="calendar-today"
                        trap-focus
                        editable
                        :disabled="true"
                      ></b-datepicker>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="workOrderType"
                      label="Work Order Type"
                      type="text"
                      vid="workOrderType"
                      placeholder="Work Order Type"
                      spellcheck="true"
                      aria-label="Work Order Type"
                      class="is-small"
                      v-model="formData.workOrderType"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-6">
                    <b-field label="Sent to Vendor Date">
                      <b-datepicker
                        v-model="formData.sentToVendorDate"
                        label="Sent To Vendor Date"
                        :show-week-number="showWeekNumber"
                        icon="calendar-today"
                        trap-focus
                        editable
                        :disabled="true"
                      ></b-datepicker>
                      <input
                        type="hidden"
                        id="TestCompletedDate"
                        v-model="formData.completedDate"
                      />
                    </b-field>
                  </div>
                </div>

                <div class="is-12 pt-5" v-show="false">
                  <valid-input
                    style="width:50px !important;"
                    name="CompletedNote"
                    label="Completed Notes"
                    type="textarea"
                    vid="CompletedNote"
                    placeholder="Completed Notes"
                    spellcheck="true"
                    aria-label="Completed Notes"
                    rules="max:8000"
                    class="is-small"
                    v-model="formData.completedNote"
                    :disabled="true"
                  />
                  <input type="hidden" id="TestCompletedNote" v-model="formData.completedNote" />
                </div>

                <div class="is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="title"
                    label="Title"
                    type="text"
                    vid="Title"
                    placeholder="Title"
                    spellcheck="true"
                    aria-label="Title"
                    rules="required|max:100"
                    class="is-small"
                    v-model="formData.title"
                    :disabled="true"
                  />
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="Description"
                    label="Description"
                    type="textarea"
                    vid="Description"
                    placeholder="Description"
                    spellcheck="true"
                    aria-label="Description"
                    rules="required|max:8000"
                    class="is-small"
                    v-model="formData.description"
                    :disabled="true"
                  />
                </div>

                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="LocationDescription"
                    label="Location Description"
                    type="textarea"
                    vid="LocationDescription"
                    placeholder="Location Description"
                    spellcheck="true"
                    aria-label="Location Description"
                    rules="max:200"
                    class="is-small"
                    v-model="formData.locationDescription"
                    :disabled="true"
                  />
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    tests-id="special-instructions-input"
                    style="width:50px !important;"
                    name="SpecialInstructions"
                    label="Special Instructions"
                    type="textarea"
                    vid="SpecialInstructions"
                    id="SpecialInstructions"
                    placeholder="Special Instructions"
                    spellcheck="true"
                    aria-label="Special Instructions"
                    rules="max:8000"
                    class="is-small"
                    v-model="formData.specialInstructions"
                    :disabled="true"
                  />
                  <input
                    id="TestSpecialInstructions"
                    type="hidden"
                    v-model="formData.specialInstructions"
                  />
                </div>
                <div class="columns pt-5">
                  <div class="column is-2">
                    <button
                      @click.prevent="completeWorkOrder()"
                      type="button"
                      :disabled="loading"
                      class="button is-primary is-rounded"
                      tests-id="complete-work-order-button"
                    >
                      Complete Work Order
                    </button>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field" v-if="errors && errors[0]">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
    <div @click="hideShowMap" style="cursor: pointer" class="href-overflow-wrap">
      {{ hideShowMapText }}
      <i style="font-size: 8pt; vertical-align: baseline !important;" class="icon-map" />
    </div>
    <div v-if="hideShowMapFlag && (hideShowMapFlag === true || hideShowMapFlag === 'true')">
      <GoogleMap
        mapStyle="satellite"
        :accessToken="accessToken"
        :longitude="
          Number.parseFloat(formData.longitude && formData.longitude !== 0 ? formData.longitude : 0)
        "
        :latitude="
          Number.parseFloat(formData.latitude && formData.latitude !== 0 ? formData.latitude : 0)
        "
        :zoomLevel="parseInt(19)"
        :workOrderId="parseInt(workOrderID)"
        :showUpload="false"
        :reloadParent="reloadParentImages"
        :readOnlyMap="true"
        :vendorHoaId="vendorHoaId"
      />
    </div>
    <Modal :toggle.sync="completeToggle">
      <CompleteModal :workOrderID="parseInt(workOrderID)" :workOrder="currentWorkOrder" />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import CompleteModal from '@/components/WorkOrderCompleteModal'
import Modal from '@/components/Modal'
import GoogleMap from '@/components/googlemaps'
import { data } from './keys/data'
import { methods } from './keys/methods'

// components
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'UpdateWorkOrder',
  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    PageContentLayoutOnly,
    CompleteModal,
    Modal,
    GoogleMap
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    }),
    locationFilterSuggestions() {
      if (
        this.addressFilterQuery &&
        this.addressDropDownList &&
        this.addressDropDownList != undefined
      ) {
        return this.addressDropDownList.filter(
          a => a.label.indexOf(this.addressDropDownList.toLowerCase()) !== -1
        )
      } else {
        return this.addressDropDownList
      }
    },
    vendorFilterSuggestions() {
      if (
        this.vendorFilterQuery &&
        this.vendorDropDownList &&
        this.vendorDropDownList != undefined
      ) {
        return this.vendorDropDownList.filter(
          a => a.label.indexOf(this.vendorDropDownList.toLowerCase()) !== -1
        )
      } else {
        return this.vendorDropDownList
      }
    }
  },

  mounted() {
    this.reload()
  },

  watch: {
    vendorHoaId() {
      this.reload()
    },
    completeToggle() {
      if (this.completeToggle == false) {
        if (this.isDebug == true) console.debug('...in completeToggle()')
        this.completeToggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
        this.reload()
      }
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        workOrderUpdate: { title: 'Update Work Order' },
        photos: { title: 'Photos' },
        notes: { title: 'Notes' }
      }
    }
  }
}
</script>

<style lang="scss">
#update-work-order .my-grid-class {
  height: 400px;
}

#update-work-order tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
